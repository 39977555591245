import moment from "moment";
import { memo } from "react";
import Flatpickr from "react-flatpickr";
import { FormGroup, Label } from "reactstrap";

const DateInputField = ({ field, values, setFieldValue }: any) => {
  return (
    <FormGroup className="mb-20 position-relative">
      <Label className="font-14 fw-bold color-black2 mb-10" for="exampleDate">
        {field.label}
      </Label>
      <Flatpickr
        value={values.expiration}
        className="font-14 fw-normal color-black2 bg-white grey-border custom-input h-50px w-100"
        // @ts-ignore
        selected={values.credentialPoint?.[field.key]}
        options={{ dateFormat: "Y-m-d" }}
        onChange={(date: any) => {
          setFieldValue(
            `credentialPoint.${field.key}`,
            moment(date[0]).format("YYYY-MM-DD")
          );
        }}
      />
      <button
        className="bg-transparent border-none position-absolute calendar-btn"
        type="button"
      >
        <i className="far fa-calendar-alt color-light-grey font-14"></i>
      </button>
    </FormGroup>
  );
};

export default memo(DateInputField);
