export const environment = {
    appStatus : process.env.REACT_APP_ENVIORNMENT,
    apiUrl: process.env.REACT_APP_APIURL || "",
    accountsPortalDomain: process.env.REACT_APP_ACCOUNTSPORTALDOMAIN || "",
    privacy: process.env.REACT_APP_PRIVACY || "",
    tCondition: process.env.REACT_APP_TCONDITION || "",
    edexaDomain: process.env.REACT_APP_EDEXADOMAIN || "",
    moreFromEdexaLibrary: process.env.REACT_APP_MOREFROMEDEXALIBRARY || "",
    accountAPI: process.env.REACT_APP_ACCOUNTSAPIENDPOINT || "",
    accountLogin: process.env.REACT_APP_AUTHWITHEDEXALOGIN || ""
}