import { useSelector } from "react-redux";
import { IRootReducer } from "store/rootRededucer";

const LoadingTableComponent = () => {
  const { isLoading } = useSelector((state: IRootReducer) => state.loading);
  return (
    <tr>
      {isLoading ? (
        <td colSpan={3} className="font-16 color-light-grey">
          Loading...
        </td>
      ) : (
        <td colSpan={3} className="font-16 color-light-grey">
          No Records Found
        </td>
      )}
    </tr>
  );
};

export default LoadingTableComponent;
