import { Header } from "core/layout/header";
import { Link } from "react-router-dom";
import Tick from "../assets/images/svg/tick.svg";

const ThankYou = () => {
  return (
    <>
      <Header />
      <div className="d-flex flex-column text-center align-items-center justify-content-center minheight-100 mx-auto container">
        <img src={Tick} alt="tick" />
        <p
          className="color-black1 font-50 fw-bold mt-20 mb-20"
          style={{ lineHeight: "initial" }}
        >
          Thank you
        </p>
        <p className="font-20 color-black2">
          Your documents have been submitted and our team will update you within
          the next 48 business hours.
        </p>
        <p className="font-20 color-black2">
          mean time you can check our
          <Link to="#" className="color-dark-blue fw-bold">
            {" "}
            website
          </Link>{" "}
          and
          <Link to="#" className="color-dark-blue fw-bold">
            {" "}
            terms and conditions
          </Link>{" "}
          to get verified.
        </p>
      </div>
    </>
  );
};

export default ThankYou;
