import { getGlobalSettingAPI } from "actions/common";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setGlobalSetting } from "store/globalSettingSlice";
import { setLoading } from "store/loaderSlice";
import { constants } from "utils/constants";
import { getDecryptedSessionStorage, setEncryptedSessionStorage } from "utils/functions";

const useGlobalSetting = () => {
  const dispatch = useDispatch();

  const handleGlobalSettingAction = () => {
    dispatch(setLoading(true));
    getGlobalSettingAPI().then((res) => {
      if (res.status === 200) {
        dispatch(setGlobalSetting(res.data));
        setEncryptedSessionStorage(
          constants.sessionStorageKeys.globalSetting,
          res.data
        );
      }
      dispatch(setLoading(false));
    });
  };

  useEffect(() => {
    if(!getDecryptedSessionStorage(constants.sessionStorageKeys.globalSetting)){
        handleGlobalSettingAction();
    }
  }, []);

  return null;
};

export default useGlobalSetting;
