import { Header } from "core/layout/header";
import { useRoutes } from "react-router-dom";
import { privateRoutes } from "./Routes";

 const PrivateRouteComponent = () => {
  const renderPrivateRouter = useRoutes(privateRoutes)
  return (
    <>
      <Header />
      {renderPrivateRouter}
    </>
  );
};

export default PrivateRouteComponent