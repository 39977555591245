import React, { FC } from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

interface IFormTextInputType {
  label: string;
  fieldname: string;
  placeholder: string;
  handleOnInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type: string;
  required: string;
  value: string;
  formik: any;
  touched: any;
  handleBlur: any;
}

const FormTextInputType: FC<IFormTextInputType> = ({
  label,
  fieldname,
  placeholder,
  handleOnInputChange,
  type,
  required,
  value,
  formik,
  touched,
  handleBlur,
  ...rest
}: any) => {
  return (
    <FormGroup className="mb-20">
      <Label className="font-14 fw-bold color-black2 mb-10">{label}</Label>
      <Input
        type={type}
        placeholder={placeholder}
        className="font-14 fw-normal color-black2 bg-white grey-border custom-input h-50px"
        name={fieldname}
        onChange={(e) => handleOnInputChange(e)}
        required={required}
        value={value}
        onBlur={handleBlur}
        {...rest}
        invalid={
          touched?.[fieldname]
            ? Boolean(formik.errors?.[fieldname] && formik.touched?.[fieldname])
            : false
        }
      />
      <FormFeedback>
        {touched?.[fieldname] ? formik.errors[fieldname] : null}
      </FormFeedback>
    </FormGroup>
  );
};

export default React.memo(FormTextInputType);
