import { HTTPService } from "utils";
import { apiEndPoint } from "utils/constants";

export const getHolderAndVerifierList = (params?: {
  page?: number;
  limit?: number;
  search?: string;
}) => {
  return HTTPService.get(apiEndPoint.getHolderAndVerifierAPI, { params });
};
