import { FC, memo } from "react";
import { toastSuccess } from "utils/functions";

const HeaderAppComponent: FC<IHeaderAppComponent> = ({
  imgSrc,
  name,
  disable,
  onClick,
  emailIcon,
}) => {
  const user: any = undefined;

  return (
    <div
      className={`d-flex align-items-center justify-content-start flex-column project ${
        disable && disable
      }`}
      onMouseEnter={() => {
        disable && toastSuccess("Coming Soon");
      }}
      onClick={(e) => {
        disable || window.open(onClick, "_blank");
      }}
    >
      <div className="app-img">
        {emailIcon ? (
          <span className="account-icon">
            {user?.email[0].toUpperCase() ? user?.email[0].toUpperCase() : "A"}
          </span>
        ) : (
          <img src={imgSrc} alt="logo" width={36} height={36} />
        )}
      </div>
      <p className="font-14 color-dark">{name}</p>
    </div>
  );
};

export default memo(HeaderAppComponent);

interface IHeaderAppComponent {
  imgSrc?: any;
  name: string;
  disable?: string;
  onClick?: string;
  emailIcon?: boolean;
}
