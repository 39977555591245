import { MainTitle, SubTitle } from "components/common";
import { CreateHolderForm } from "components/holders";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import { constants } from "utils/constants/constants";
import { IgetHoldersListAPIRes, getHoldersListAPI } from "../actions/holdersAPI";
import SearchComponent from "../components/common/Search";
import TableComponent from "../components/listTable/ListTable";
import Footer from "../core/layout/Footer";
import { setLoading } from "../store/loaderSlice";
import { handleEmptyObject } from "../utils/functions/commonFunctions";

const Holders = () => {
  const dispatch = useDispatch();

  const [holderList, setHolderList] = useState<IgetHoldersListAPIRes[]>([]);
  const [totalListCount, setTotalListCount] = useState<number>(0);
  const [paginationConfig, setPaginationConfig] = useState(
    constants.initialPaginationConfig
  );

  const getListing = () => {
    dispatch(setLoading(true));
    const data = handleEmptyObject(paginationConfig);
    getHoldersListAPI(data).then((res) => {
      if (res.status === 200) {
        setHolderList(res.data);
        setTotalListCount(res.count);
        dispatch(setLoading(false));
      }
    });
  };

  useEffect(() => {
    getListing();
  }, [paginationConfig]);

  return (
    <>
      <div className="holders">
        <MainTitle title={constants.componentName.holders} />
        <Row>
          <Col sm="12" lg="9">
            <div className="list-box rounded-10">
              <div className="d-flex justify-content-between align-items-center flex-wrap mb-10">
                <SubTitle title={constants.details} />
                <SearchComponent
                  placeholder="Search by name/email"
                  paginationConfig={paginationConfig}
                  setPaginationConfig={setPaginationConfig}
                />
              </div>
              <TableComponent
                listData={holderList}
                totalCount={totalListCount}
                tableHeaders={constants.addUsertableHeaders}
                type={constants.type.holders}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
              />
            </div>
          </Col>
          <Col sm="12" lg="3" className="holder-form">
            <div className="list-box rounded-10 h-100">
              <SubTitle title="Create Holder" />
              <CreateHolderForm
                callListingAPI={getListing}
                role={constants.userType.user}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default Holders;
