export const apiEndPoint = {
  userInfoAPI: 'user-info',
  addUserAPI: 'add-user',
  getPublicCredentialAPI: 'credential/public',
  dashboardAPI: 'dashboard',
  getHoldersAPI: 'holders',
  getVerifiersAPI: 'verifiers',
  getStatusListAPI: 'status',
  getCredentialAPI: 'credentials',
  issueCredListAPI: 'credential/list',
  schemaListAPI: 'schema/list',
  schemaCreateAPI: 'schema/create',
  issueCredCreateAPI: 'credential/create',
  getHolderAndVerifierAPI: 'pre-data',
  getVerifierListAPI: 'verifiers',
  availableAppAPI: 'available-apps',
  globalSetting: 'settings',
  logoutAPI: 'auth/logout'
}
