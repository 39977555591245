import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routerLinks } from "utils/constants";
import { constants, modulesArray } from "utils/constants/constants";
import Logo from "../../../assets/images/svg/edexa-blue.svg";
import { Application, Module, ProfileModal } from "./components";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [dropdownPage, setDropdownPage] = useState(false);
  const [currentModule, setCurrentModule] = useState<string>();

  const pageToggle = () => setDropdownPage((prevState) => !prevState);
  const handleModuleClick = (path: string, module: string, toggle: boolean) => {
    toggle && pageToggle();
    setCurrentModule(module);
    navigate(path);
  };

  useEffect(() => {
    if (location.pathname.includes("public-verification")) {
      setCurrentModule("Public Verification");
    } else {
      const modul = modulesArray.find(
        (data) => data.path === location.pathname
      );
      if (modul && modul?.label !== location.pathname) {
        setCurrentModule(modul?.label);
      } else {
        if (routerLinks.createSchemaCredentials === location.pathname) {
          setCurrentModule(constants.componentName.credentialSchemas);
        } else if (routerLinks.issuecredentialNew === location.pathname) {
          setCurrentModule(constants.componentName.issueCredentials);
        }
      }
    }
  }, [location.pathname]);

  return (
    <div className="d-flex justify-content-between align-items-center w-100 header">
      <div className="d-flex cursor-pointer">
        <img
          src={Logo}
          alt="logo"
          onClick={() => handleModuleClick("/", "Dashboard", false)}
        />
        <Module
          dropdownPage={dropdownPage}
          pageToggle={pageToggle}
          currentModule={currentModule}
          handleModuleClick={handleModuleClick}
        />
      </div>
      <div className="d-flex right-header">
        <Application />
        <ProfileModal />
      </div>
    </div>
  );
}

export default React.memo(Header);
