import { IgetCredentialsListRes, getCredentialsList } from "actions/credentials/credentialsAPI";
import { MainTitle } from "components/common";
import { Footer } from "core/layout";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { setLoading } from "store/loaderSlice";
import { routerLinks } from "utils/constants";
import { constants } from "utils/constants/constants";
import { handleEmptyObject } from "utils/functions";
import SearchComponent from "../../components/common/Search";
import TableComponent from "../../components/listTable/ListTable";

const CredentialsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState<IgetCredentialsListRes[]>([]);
  const [totalListCount, setTotalListCount] = useState<number>(0);
  const [paginationConfig, setPaginationConfig] = useState(
    constants.initialPaginationConfig
  );

  const getListing = () => {
    const data = handleEmptyObject(paginationConfig);
    dispatch(setLoading(true));
    getCredentialsList(data).then((res) => {
      if(res.status === 200){
        setData(res.data.request);
        setTotalListCount(res.data.count);
        dispatch(setLoading(false));
      }
    });
  };

  useEffect(() => {
    getListing();
  }, [paginationConfig]);

  return (
    <>
      <div className="holders">
        <MainTitle title={constants.componentName.issueCredentials} />
        <Row>
          <Col sm="12" lg="12">
            <div className="list-box rounded-10">
              <div className="d-flex justify-content-between align-items-center flex-wrap mb-10">
                {/* <SubTitle title={constants.details} /> */}
                  <SearchComponent
                    placeholder="Search by Name, Holder"
                    paginationConfig={paginationConfig}
                    setPaginationConfig={setPaginationConfig}
                  />
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  {/* <Button
                    className="border-0 bg-blue ml-10"
                    onClick={() => navigate(routerLinks.issuecredentialNew)}
                  >
                    {constants.componentName.issueCredential}
                  </Button> */}
                  <button
                    type="submit"
                    style={{ width: "150px" }}
                    className="bg-blue ml-10 color-white rounded-2 py-15 font-14"
                    onClick={() => navigate(routerLinks.issuecredentialNew)}

                  >
                    {constants.componentName.issueCredential}

                  </button>
                </div>
              </div>
              <TableComponent
                listData={data}
                totalCount={totalListCount}
                tableHeaders={constants.credentialSchemasTableHeaders}
                type={constants.type.credentials}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default CredentialsList;
