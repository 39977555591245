import { useRoutes } from "react-router-dom";
import { underMiantenanceRoutes } from "./Routes";

const UnderMaintenaceRouteComponent = () => {
  const renderUnderMaintenance = useRoutes(underMiantenanceRoutes)
  return (
    <>{renderUnderMaintenance}</>
  )
}

export default UnderMaintenaceRouteComponent