import { apiEndPoint } from "../utils/constants/apiEndPoints";
import HTTPService from "../utils/httpservice";

export const getVerifiersList = (params?: {
  page: number;
  limit: number;
  search?: string;
}): Promise<IgetVerifiersList> => {
  return HTTPService.get(apiEndPoint.getVerifiersAPI, { params });
};

interface IgetVerifiersList {
  message: string;
  status: number;
  data: any[];
}
