import axios, { AxiosResponse } from "axios";
import { logOutUserFromCurrentAppOnly } from "store/authSlice";
import { environment } from "utils/environment/environment";
import { getUserDetails } from "../actions/user/userInfoAPI";
import store from "../store";
import { setLoading } from "../store/loaderSlice";
import { constants } from "./constants/constants";
import { getEncryptedCookie, handleLogout, toastError } from "./functions/commonFunctions";

const axiosInstance = axios.create({
  baseURL: environment.apiUrl,
});

const errorInterceptor = (errorResponse: AxiosResponse) => {
  store.dispatch(setLoading(false));
  const { message, status } = errorResponse?.data || {};
  if (status === 401) {
    if (message === "You are not authorised to login") {
      store.dispatch(logOutUserFromCurrentAppOnly());
    }else{
      handleLogout()
    }
  } else if (status === 406) {
    getUserDetails();
  } else if (status === 423) {
    store.dispatch(logOutUserFromCurrentAppOnly());
  } else {
    if (message) {
      toastError(message);
    }
  }
};

axiosInstance.interceptors.request.use(
  (req) => {
    const cookie = getEncryptedCookie(constants.cookieKeys.cookieUser);
    if (cookie && cookie.token) {
      req.headers.Authorization = `Bearer ${cookie.token}`;
    }
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(
  (req) => {
    return req;
  },
  (err) => {
    errorInterceptor(err.response);
    return Promise.reject(err);
  }
);

export default class HTTPService {
  static get<T = never, R = AxiosResponse<T>>(
    url: string,
    params?: any
  ): Promise<R> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(url, params)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }

  static put<T = never, R = AxiosResponse<T>>(
    url: string,
    body: any
  ): Promise<R> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(url, body)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }
  static post<T = never, R = AxiosResponse<T>>(
    url: string,
    body: any
  ): Promise<R> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(url, body)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }

  static delete<T = never, R = AxiosResponse<T>>(
    url: string,
    body: any
  ): Promise<R> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(url, { data: body })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }
}
