import { addUserAPI } from "actions/user";
import { useFormik } from "formik";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "store/loaderSlice";
import {
  commomValidationSchema,
  handleTrim,
  toastSuccess,
} from "utils/functions";
import "../../style/holderForm.css";
import FormTextInputType from "./CreateHolderInput";

interface ICreateHolderForm {
  role: string;
  callListingAPI: () => void;
}

const CreateHolderForm: FC<ICreateHolderForm> = ({ callListingAPI, role }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      role,
    },
    validationSchema: commomValidationSchema.validationSchema,
    onSubmit: (values, { resetForm }) => {
      dispatch(setLoading(true));
      addUserAPI(values).then((res:any) => {
        if (res.status === 200) {
          resetForm();
          toastSuccess(res?.message);
          callListingAPI();
        }
        dispatch(setLoading(false));
      });
    },
  });
  const { handleSubmit, setFieldValue, values, touched, handleBlur } = formik;

  const handleOnInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleTrim(e.target.value, e.target.name, setFieldValue);
  };

  return (
    <div>
      <FormTextInputType
        label={"First name"}
        placeholder={"ex. Jonathan"}
        fieldname={"firstName"}
        handleOnInputChange={handleOnInputChange}
        type={"text"}
        required={"required"}
        value={values.firstName}
        formik={formik}
        touched={touched}
        handleBlur={handleBlur}
      />
      <FormTextInputType
        label={"Last name"}
        placeholder={"ex. Doe"}
        fieldname={"lastName"}
        handleOnInputChange={handleOnInputChange}
        type={"text"}
        required={"required"}
        value={values.lastName}
        formik={formik}
        touched={touched}
        handleBlur={handleBlur}
      />
      <FormTextInputType
        label={"Email"}
        placeholder={"ex. jonathan@gmail.com"}
        fieldname={"email"}
        handleOnInputChange={handleOnInputChange}
        type={"name"}
        required={"required"}
        value={values.email}
        formik={formik}
        touched={touched}
        handleBlur={handleBlur}
      />
      <button
        type="submit"
        className="bg-blue color-white w-100 rounded-2 py-15 font-14"
        onClick={() => handleSubmit()}
      >
        Submit
      </button>
    </div>
  );
};

export default React.memo(CreateHolderForm);
