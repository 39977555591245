import { apiEndPoint } from "../utils/constants/apiEndPoints";
import HTTPService from "../utils/httpservice";

export interface Iparams {
  page: number;
  limit: number;
  search?: string;
}

export const getIssuersList = (params: Iparams): Promise<IgetIssuersList> => {
  return HTTPService.get(apiEndPoint.getVerifierListAPI, { params });
};

export interface IgetIssuersListData {
  createdAt: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  _id: string;
}
interface IgetIssuersList {
  status: number;
  message: string;
  count: number;
  data: IgetIssuersListData[];
}
