import { userLogout } from "actions/common";
import { HeaderImg } from "components/common";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { setLoading } from "store/loaderSlice";
import { environment } from "utils/environment/environment";
import { goToPage, handleLogout } from "utils/functions";
import defaultImg from "../../../../assets/images/defaultImg.png";

const ProfileModal = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state: any) => state.userDetails.user);
  const userDetails = useSelector((state: any) => state.userDetails.user);
  const [dropdownOpenProfile, setDropdownOpenProfile] = useState(false);

  const profileToggle = () => setDropdownOpenProfile((prevState) => !prevState);

  const handleUserLogout = () => {
    dispatch(setLoading(true));
    userLogout()
      .then((res: any) => {
        if (res.data.status === 200) {
          handleLogout();
          dispatch(setLoading(false));
        }
      })
      .catch((err: any) => {
        handleLogout();
        dispatch(setLoading(false));
      });
  };
  return (
    <>
      <Dropdown
        isOpen={dropdownOpenProfile}
        toggle={profileToggle}
        className="d-flex align-items-center justify-content-center header-dropdown"
      >
        <DropdownToggle title="My profile" className="menu-profile">
          <HeaderImg
            src={userData?.profilePic ? userData?.profilePic : defaultImg}
            width="0px"
            height="0px"
          />
          <i className="fas fa-chevron-down down-arrow"></i>
        </DropdownToggle>
        <DropdownMenu className="rounded-10 profile-list">
          <DropdownItem className="project-list">
            <p className="font-11 color-black2 bg-lightblue text-center profile-title rounded-2">
              This account is managed by edeXa.
              <Link
                to="#"
                onClick={() => goToPage(environment.edexaDomain)}
                className="color-blue fw-bold"
              >
                {" "}
                Learn more
              </Link>
            </p>
            <div className="profile-detail">
              <HeaderImg
                src={userData?.profilePic ? userData?.profilePic : defaultImg}
                width="80px"
                height="80px"
              />
              <p className="color-black1 font-16 fw-bold">{`${
                userDetails?.firstName
                  ? userDetails?.firstName + " " + userDetails?.lastName
                  : String(userDetails?.email).split("@")[0]
              }`}</p>
              <p className="color-black1 font-14 fw-normal">
                {userDetails?.email}
              </p>
              <div
                className="font-13 bg-blue color-white rounded-2 manage-button w-100"
                onClick={() => goToPage(environment.accountsPortalDomain)}
              >
                Manage your edeXa Account
              </div>
            </div>
          </DropdownItem>
          <div className="profile-footer w-100">
            <div className="d-flex justify-content-center align-items-center logout">
              <button
                className="font-13 logout-btn color-black-2 rounded-2 border-grey w-100"
                onClick={handleUserLogout}
              >
                Logout
              </button>
            </div>
            <div className="d-flex justify-content-center align-items-center policy">
              <Link
                to="#"
                className="font-11 color-light-grey fw-bold"
                onClick={() => goToPage(environment.privacy)}
              >
                Privacy Policy
              </Link>
              <span className="font-11" style={{ padding: "0 10px" }}>
                -
              </span>
              <Link
                to="#"
                className="font-11 color-light-grey fw-bold"
                onClick={() => goToPage(environment.tCondition)}
              >
                Terms and Conditions
              </Link>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default ProfileModal;
  