import { FC, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { constants } from "utils/constants/constants";

interface IRowPerPage {
  calculateLastPageRange: () => number;
  setRowPerPage: any;
  setPaginationConfig: React.Dispatch<
    React.SetStateAction<{
      search: string;
      page: number;
      limit: number;
    }>
  >;
  paginationConfig: {
    search: string;
    page: number;
    limit: number;
  };
  rowPerPage: number;
  totalCount: number;
}

const RowPerPage: FC<IRowPerPage> = ({
  calculateLastPageRange,
  setRowPerPage,
  setPaginationConfig,
  paginationConfig,
  rowPerPage,
  totalCount,
}) => {
  const [dropdownPage, setDropdownPage] = useState(false);
  const pageToggle = () => setDropdownPage((prevState) => !prevState);

  const onHandleRowPerPageChange = (el: any) => {
    setRowPerPage(el);
    setDropdownPage(false);
    setPaginationConfig({
      ...paginationConfig,
      page: 1,
      limit: el,
    });
  };

  const calculateFirstPageRange = () => {
    if (paginationConfig.page === 1) {
      return paginationConfig.page;
    } else {
      return paginationConfig.limit * (paginationConfig.page - 1) + 1;
    }
  };
  return (
    <div className="d-flex align-items-center">
      <p className="font-13 color-light-grey m-0">Rows per page</p>
      <Dropdown
        isOpen={dropdownPage}
        toggle={pageToggle}
        className="d-flex align-items-center justify-content-center ml-10 mr-10"
      >
        <DropdownToggle className="bg-white color-light-grey w-100 h-100 border-grey p-10 row-count">
          {rowPerPage}
          <i className="fas fa-chevron-down color-light-grey font-12 ml-10"></i>
        </DropdownToggle>
        <DropdownMenu className="p-0 rounded-0 w-100 border-grey">
          <div style={{ cursor: "pointer" }}>
            {constants.rowsPerPage.map((el: any, index: number) => {
              return (
                <p
                  key={index}
                  onClick={() => onHandleRowPerPageChange(el)}
                  className="color-dark-grey font-14 p_5 m-0 page-name"
                >
                  {el}
                </p>
              );
            })}
          </div>
        </DropdownMenu>
      </Dropdown>
      <p className="font-13 color-light-grey m-0">
        {calculateFirstPageRange()} - {calculateLastPageRange()} of {totalCount}
      </p>
    </div>
  );
};

export default RowPerPage;
