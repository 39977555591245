import { memo } from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

const SelectSchemaDropdown = ({
  schemaList,
  handleOnSchemaChange,
  errors,
  touched,
}:any) => {
  return (
    <>
      <FormGroup className="mb-20">
        <Label className="font-14 fw-bold color-black2 mb-10">
          Select Schema
        </Label>
        <Input
          type="select"
          className="font-14 color-black2 form-select custom-select bg-white h-50px border-gray"
          aria-label="Default select example"
          invalid={Boolean(
            errors.credentialSchemaId && touched.credentialSchemaId
          )}
          onChange={(e) => handleOnSchemaChange(JSON.parse(e.target.value))}
        >
          <option
            selected
            disabled
            className="font-14 fw-normal color-black2"
            value=""
          >
            Select your Schema
          </option>
          {schemaList &&
            schemaList.map((schema:any, index:number) => {
              return (
                <option
                  value={JSON.stringify(schema)}
                  className="font-14 fw-normal color-black2"
                  key={index}
                >
                  {schema.name}
                </option>
              );
            })}
        </Input>
        <FormFeedback>{errors.credentialSchemaId}</FormFeedback>
      </FormGroup>
    </>
  );
};

export default memo(SelectSchemaDropdown);
