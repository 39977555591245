import { FC } from "react";

interface ISubTitle {
  title: string;
}

const SubTitle: FC<ISubTitle> = ({ title }) => {
  return <p className="color-black1 font-20 fw-bold">{title}</p>;
};

export default SubTitle;
