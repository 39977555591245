import React from "react";
import { Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import MainTitle from "./MainTitle";

const Information = ({ errors, values, setFieldValue, touched, handleBlur }: any) => {
  return (
    <>
      <div className="m-auto bg-white rounded-10 p-20 information-section">
        <MainTitle title="Credential Information" />
        <p className="font-14 color-light-grey mb-20">
          Create your dynamic credentials on the go.
        </p>
        <div className="d-flex align-items-start logo-section">
          <div className="w-100">
            <FormGroup className="mb-20">
              <Label className="font-14 fw-bold color-black2 mb-10">Credential Name</Label>
              <Input
                type="text"
                placeholder="Credential Name"
                className="font-14 fw-normal color-black2 bg-white grey-border custom-input h-50px"
                name="name"
                value={values?.name}
                onChange={(event) =>
                  setFieldValue(event.target.name, event.target.value)
                }
                invalid={Boolean(touched.name) && Boolean(errors.name)}
              />
              {Boolean(touched.name) && (
                <FormFeedback>{errors.name}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label className="font-14 fw-bold color-black2 mb-10">
                Description
              </Label>
              <Input
                style={{ minHeight: "calc(2em + 3rem + 2px)" }}
                type="textarea"
                placeholder="Description"
                className="font-14 fw-normal color-black2 bg-white grey-border custom-input "
                name="comments"
                onChange={(event) =>
                  setFieldValue(event.target.name, event.target.value)
                }
                value={values?.comments}
                invalid={Boolean(touched.comments) && Boolean(errors.comments)}
              />
              {Boolean(touched.comments) && (
                <FormFeedback>{errors.comments}</FormFeedback>
              )}
            </FormGroup>
          </div>
        </div>
      </div>
      <div className="m-auto information">
        <Row className="h-100">
          <Col xs="12" sm="6" lg="6" className="h-100">
            <div className="basic-info w-100 rounded-10 position-relative">
              <p className="font-40 color-black1 fw-light">How to </p>
              <p className="font-40 color-black1">get started</p>
              <button className="color-black1 font-20 fw-bold more-link bg-transparent">
                Learn more
              </button>
            </div>
          </Col>
          <Col xs="12" sm="6" lg="6" className="h-100">
            <div className="bg-white rounded-10 h-100 info-list">
              <MainTitle title="Dashboard" />
              <div>
                <p className="color-light-grey font-14 mb-10 pl-25 position-relative">
                  <i className="fas fa-check-circle mr-10 position-absolute" />
                  Image should be clearly visible.
                </p>
                <p className="color-light-grey font-14 mb-10 pl-25 position-relative">
                  <i className="fas fa-check-circle mr-10 position-absolute" />
                  Document should be valid within a specific time period.
                </p>
                <p className="color-light-grey font-14 mb-10 pl-25 position-relative">
                  <i className="fas fa-check-circle mr-10 position-absolute" />
                  Please make sure your face is clearly visible.
                </p>
                <p className="color-light-grey font-14 mb-10 pl-25 position-relative">
                  <i className="fas fa-check-circle mr-10 position-absolute" />
                  Note has today date and "Kryptono".
                </p>
                <p className="color-light-grey font-14 mb-10 pl-25 position-relative">
                  <i className="fas fa-check-circle mr-10 position-absolute" />
                  Maximum file size should be 150KB, and the dimensions should not exceed 1080 pixels.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default React.memo(Information);
