export const routerLinks = {
  home: "/",
  dashboard: "/dashboard",
  holders: "/holders",
  issuecredential: "/issue-credentials",
  credentials: "/credential-schemas",
  verifiers: "/verifiers",
  thankyou: "/thankyou",
  issuecredentialNew: "/issuecredential/new",
  createSchemaCredentials: "/credential-schemas/create",
  publicVerificationId: "/public-verification/:id",
  unauthorized: "/unauthorized",
  underMaintenance:"/under-maintenace"
};
