import { combineReducers } from "redux";
import globalSettingSlice from "./globalSettingSlice";
import loadingSlice, { ILoadingReducer } from "./loaderSlice";
import userDetailsSlice from "./userSlice";

import { IgetGlobalSettingAPIData } from "actions/common/getGlobalSettingAPI";
import authSlice from "./authSlice";

const rootReducer = combineReducers<IRootReducer>({
  auth: authSlice,
  loading: loadingSlice,
  userDetails: userDetailsSlice,
  globalSetting: globalSettingSlice,
});

export default rootReducer;

export interface IRootReducer {
  auth: any;
  loading: ILoadingReducer;
  userDetails: any;
  globalSetting: IgetGlobalSettingAPIData;
}
