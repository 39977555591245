import { routerLinks } from './routerLinks';
export const constants = {
  debounceTimeInMilliseconds: 500,

  details: "Details",
  defaultCountryCode: "ch",
  dateFormat: "DD-MM-YYYY | h:mm",
  toasterPosition: "bottom-left",

  onlyCharacterValidationRegex: /^[a-zA-Z '!@#$%&-\s]+$/,
  onlyNumberRegex: /^[0-9]*$/,

  rowsPerPage: [10, 20, 30, 40, 50],
  addUsertableHeaders: ["Name", "Email", "Date Created"],
  credentialSchemasTableHeaders: ["Name", "Holder", "Date Created"],
  credentialsSchemaListTableHeaders: ["Name", "Description", "Status", "Date Created",],

  toasterMessages: {
    comingSoon: "Coming Soon",
  },
  sessionStorageKeys: {
    globalSetting: "globalSetting",
    appList: "appList"
  },
  localStorageKeys: {
    userInfo: "userInfo",
    userToken: "userToken",
  },
  userType: {
    verifier: "verifier",
    user: "user",
  },
  type: {
    holders: "holders",
    credentials: "credentials",
    schemaList: "schemaList",
  },
  componentName: {
    dashboard: "Dashboard",
    holders: "Holders",
    verifiers: "Verifiers",
    issueCredential: "Issue Credential",
    issueCredentials: "Issue Credential",
    credentialSchemas: "Credential Schemas",
    thankYou: "Thank You",
  },
  initialPaginationConfig: {
    search: "",
    page: 1,
    limit: 10,
  },
  cookieKeys: {
    cryptoSecretKey: "edexaUser",
    cookieInitial: "edexa",
    cookieUser: "CookieUser",
  },
  validationMessages: {
    onlyAlphabets: "Please enter aplhabets only",
    onlyNumerical: "Please enter numerical value",
    firstName: {
      require: "First Name is required",
      min: "First Name must be at least 3 characters",
      max: "First Name must be at most 50 characters"
    },
    lastName: {
      require: "Last Name is required",
      min: "Last Name must be at least 3 characters",
      max: "Last Name must be at most 50 characters"
    },
    email: {
      required: "Email is invalid",
      invalid: "Email is required",
    },
    name: "Name is required",
    description: "Description is required",
    label: "Label is required",
  },
};

export const modulesArray = [
  { label: constants.componentName.dashboard, path: routerLinks.home },
  { label: constants.componentName.holders, path: routerLinks.holders },
  {
    label: constants.componentName.credentialSchemas,
    path: routerLinks.credentials,
  },
  {
    label: constants.componentName.issueCredentials,
    path: routerLinks.issuecredential,
  },
  { label: constants.componentName.verifiers, path: routerLinks.verifiers },
];

export const allRoutesArray = [
  { label: constants.componentName.dashboard, path: routerLinks.home },
  { label: constants.componentName.dashboard, path: routerLinks.dashboard },
  { label: constants.componentName.holders, path: routerLinks.holders },
  {
    label: constants.componentName.issueCredentials,
    path: routerLinks.issuecredential,
  },
  {
    label: constants.componentName.credentialSchemas,
    path: routerLinks.credentials,
  },
  { label: constants.componentName.verifiers, path: routerLinks.verifiers },
  { label: constants.componentName.thankYou, path: routerLinks.thankyou },
  {
    label: constants.componentName.issueCredentials,
    path: routerLinks.issuecredentialNew,
  },
  {
    label: constants.componentName.credentialSchemas,
    path: routerLinks.createSchemaCredentials,
  },
];

export enum ModalTitle {
  CREDENTIALS_DETAILS = "Credential Details",
}

export enum ModalType {
  CREDENTIALS = "credentials",
  SCHEMA = "schema"
}