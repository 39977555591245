import { FC } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

interface IPaginationComponent {
  setPaginationConfig: any;
  paginationConfig: any;
  calculateLastPageRange: any;
  totalCount: any;
}

const PaginationComponent: FC<IPaginationComponent> = ({
  setPaginationConfig,
  paginationConfig,
  calculateLastPageRange,
  totalCount,
}) => {
  const handleNext = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: paginationConfig.page + 1,
    });
  };

  const handlePrevious = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: paginationConfig.page - 1,
    });
  };

  const goToFirstPage = () => {
    setPaginationConfig({
      ...paginationConfig,
      page: 1,
    });
  };

  const goToLastPage = () => {
    setPaginationConfig({
      ...paginationConfig,
      // @ts-ignore
      page: Math.ceil(totalCount / paginationConfig.limit),
    });
  };

  return (
    <Pagination className="pagination-section">
      <PaginationItem
        className={`pagination-number mr-10 ${
          paginationConfig.page === 1 && "disabled"
        }`}
        onClick={() => (paginationConfig.page > 1 ? goToFirstPage() : null)}
      >
        <PaginationLink
          href="#"
          className="p_5 grey-border rounded-2 d-flex align-items-center justify-content-center"
        >
          <i className="fas fa-angle-double-left font-13 d-flex align-items-center justify-content-center"></i>
        </PaginationLink>
      </PaginationItem>

      <PaginationItem
        className={`pagination-number mr-10 ${
          paginationConfig.page === 1 && "disabled"
        }`}
        onClick={() => (paginationConfig.page > 1 ? handlePrevious() : null)}
      >
        <PaginationLink
          href="#"
          className="p_5 grey-border rounded-2 d-flex align-items-center justify-content-center"
        >
          <i className="fas fa-chevron-left font-13 d-flex align-items-center justify-content-center"></i>
        </PaginationLink>
      </PaginationItem>

      {/* @ToDo
                  This component generates error for onclick.
                */}
      <PaginationItem
        className={`pagination-number mr-10 ${
          calculateLastPageRange() === totalCount && "disabled"
        }`}
        onClick={() =>
          calculateLastPageRange() < totalCount ? handleNext() : null
        }
      >
        <PaginationLink
          href="#"
          className="p_5 grey-border rounded-2 d-flex align-items-center justify-content-center"
        >
          <i className="fas fa-chevron-right font-13 d-flex align-items-center justify-content-center"></i>
        </PaginationLink>
      </PaginationItem>

      <PaginationItem
        className={`pagination-number ${
          calculateLastPageRange() === totalCount && "disabled"
        }`}
        onClick={() =>
          calculateLastPageRange() < totalCount ? goToLastPage() : null
        }
      >
        <PaginationLink
          href="#"
          className="p_5 grey-border rounded-2 d-flex align-items-center justify-content-center"
        >
          <i className="fas fa-angle-double-right font-13 d-flex align-items-center justify-content-center"></i>
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
};

export default PaginationComponent;
