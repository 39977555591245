import { getSchemaList } from 'actions/schema'
import { IgetSchemaListData } from 'actions/schema/schemaAPI'
import { MainTitle } from 'components/common'
import { Footer } from 'core/layout'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { setLoading } from 'store/loaderSlice'
import { routerLinks } from 'utils/constants'
import { constants } from 'utils/constants/constants'
import { handleEmptyObject } from 'utils/functions'
import SearchComponent from '../../components/common/Search'
import TableComponent from '../../components/listTable/ListTable'

const CredentialsSchemaList = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [totalListCount, setTotalListCount] = useState<number>(0)
  const [holderList, setHolderList] = useState<IgetSchemaListData[]>(
    []
  )
  const [paginationConfig, setPaginationConfig] = useState(
    constants.initialPaginationConfig
  )

  const getListing = () => {
    dispatch(setLoading(true))
    const data = handleEmptyObject(paginationConfig)
    getSchemaList(data).then((res) => {
      if (res.status === 200) {
        setHolderList(res.data)
        setTotalListCount(res.count)
        dispatch(setLoading(false))
      }
    })
  }

  useEffect(() => {
    getListing()
  }, [paginationConfig])

  return (
    <>
      <div className="holders">
        <MainTitle
          title={constants.componentName.credentialSchemas}
        />
        <Row>
          <Col sm="12" lg="12">
            <div className="list-box rounded-10">
              <div className="d-flex justify-content-between align-items-center flex-wrap mb-10 ">
                <div className="d-flex align-items-center justify-content-between flex-wrap w-100">
                  <SearchComponent
                    placeholder="Search by name..."
                    paginationConfig={paginationConfig}
                    setPaginationConfig={setPaginationConfig}
                  />
                  {/* <Button
                    className="border-0 bg-blue ml-10"
                    onClick={() =>
                      navigate(routerLinks.createSchemaCredentials)
                    }
                  >
                    Add New
                  </Button> */}
                  <button
                    type="submit"
                    style={{ width: '120px' }}
                    className="bg-blue ml-10 color-white rounded-2 py-15 font-14"
                    onClick={() =>
                      navigate(routerLinks.createSchemaCredentials)
                    }
                  >
                    Add New
                  </button>
                </div>
              </div>
              <TableComponent
                listData={holderList}
                totalCount={totalListCount}
                tableHeaders={
                  constants.credentialsSchemaListTableHeaders
                }
                type={constants.type.schemaList}
                paginationConfig={paginationConfig}
                setPaginationConfig={setPaginationConfig}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  )
}

export default CredentialsSchemaList
