import { Holders, ThankYou, Unauthorized, Verifiers } from "pages";
import { Credentials, CredentialsList, CredentialsSchemaList, IssueCredential } from "pages/credentials";
import { Dashboard } from "pages/dashboard";
import { PublicVerification } from "pages/public";
import UnderMaintenance from "pages/underMaintenace/UnderMaintenace";
import { Navigate, Outlet, RouteObject } from "react-router-dom";
import { routerLinks } from "utils/constants";

export const privateRoutes: RouteObject[] = [
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <Dashboard />,       
      },
      {
        path: routerLinks.dashboard,
        element: <Dashboard />,
      },
      {
        path: routerLinks.holders,
        element: <Holders />,
      },
      {
        path: routerLinks.credentials,
        element: <CredentialsSchemaList />,
      },
      {
        path: routerLinks.issuecredential,
        element: <CredentialsList />,
      },
      {
        path: routerLinks.verifiers,
        element: <Verifiers />,
      },
      {
        path: routerLinks.createSchemaCredentials,
        element: <Credentials />,
      },
      {
        path: routerLinks.issuecredentialNew,
        element: <IssueCredential />,
      },
      {
        path: routerLinks.thankyou,
        element: <ThankYou />,
      },
      {
        path: routerLinks.publicVerificationId,
        element: <PublicVerification />,
      },
      { path: "*", element: <Navigate to={routerLinks.home} /> },
    ],
  },
];

export const publicRoutes: RouteObject[] = [
  {
    path: "/",
    element: <Outlet />,
    children: [
      // {
      //   index: true,
      //   element: <Navigate to={"/"} />,
      // },
      {
        path: routerLinks.publicVerificationId,
        element: <PublicVerification />,
      },
      // {
      //   path: "*",
      //   element: <Navigate to={"/"} />,
      // },
    ],
  },
];

export const unauthorizedRoutes = [
  {
    path: "/",
    element: <Outlet />,
    children: [
      { index: true, element: <Navigate to={routerLinks.unauthorized} /> },
      {
        path: routerLinks.unauthorized,
        element: <Unauthorized />,
      },
      { path: "*", element: <Navigate to={routerLinks.unauthorized} /> },
    ],
  },
];

export const underMiantenanceRoutes: RouteObject[] = [
  {
    path: "/",
    element: <Outlet />,
    children: [
      { index: true, element: <Navigate to={routerLinks.underMaintenance} /> },
      { path: routerLinks.underMaintenance, element: <UnderMaintenance /> },
      { path: "*", element: <Navigate to={routerLinks.underMaintenance} /> },
    ],
  },
];
