import { apiEndPoint } from "utils/constants";
import HTTPService from "utils/httpservice";

export const getAvailableAppAPI = ():Promise<IgetAvailableAppAPI> => {
  return HTTPService.get(apiEndPoint.availableAppAPI);
};

export interface IgetAvailableAppAPIData {
  appId:string;
  app_url:string;
  desktop_url:string;
  document_url:string;
  extension_url:string;
  id:number;
  ios_url:string;
  isAvailable:number;
  logo:string;
  logoIcon:string;
  name:string;
  order:number;
  redirect_url:string;
}

interface IgetAvailableAppAPI{
  message:string;
  status:number;
  data:IgetAvailableAppAPIData[]
}
