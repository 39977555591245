import { getCredentialDetail } from 'actions/credentials'
import { ModalCard } from 'components/listTable'
import { FC, useEffect } from 'react'
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import { ModalTitle, ModalType } from 'utils/constants/constants'
import '../../style/modal.css'

interface IDataPreviewModal {
  modal: {
    title: ModalTitle
    type: ModalType
    data: any
    isOpen: boolean
    isLoading: boolean
  }
  setModal: React.Dispatch<
    React.SetStateAction<{
      title: ModalTitle
      type: ModalType
      data: any
      isOpen: boolean
      isLoading: boolean
    }>
  >
}

const DataPreviewModal: FC<IDataPreviewModal> = ({
  modal,
  setModal
}) => {
  const toggle = () => {
    setModal({ ...modal, data: {}, isOpen: false })
  }

  const getdata = (id: string) => {
    getCredentialDetail(id)
      .then((res: any) => {
        setModal({
          data:  modal.type === ModalType.SCHEMA  ? res.data.credentialPoint : res.data.credentialPointNew,
          title: ModalTitle.CREDENTIALS_DETAILS,
          type: ModalType.CREDENTIALS,
          isOpen: true,
          isLoading: false
        })
      })
      .catch((err) => {
        toggle()
      })
  }

  useEffect(() => {
    if (modal.type === ModalType.CREDENTIALS && modal.isOpen) {
      getdata(modal.data._id)
    }
  }, [modal.isOpen])

  return (
    <Modal isOpen={modal.isOpen} className={`custom-model`} centered>
      <ModalHeader
        toggle={toggle}
        className="border-0 bg-blue text-white text-break"
      >
        {modal.title}
      </ModalHeader>
      <ModalBody className="p-0">
        {modal.isLoading ? (
          <div className="d-flex align-items-center justify-content-center">
            <Spinner className="color-blue m-3" />
          </div>
        ) : modal.type === ModalType.SCHEMA ? (
          <>
            <ModalCard title="Schema Name" value={modal.data?.name} />
            <ModalCard
              title="Schema Description"
              value={modal.data?.comments}
            />
            <ModalCard
              title="Header Fields"
              value={modal.data?.json?.headerFields[0]?.label}
            />
            <ModalCard
              title="Primary Fields"
              value={modal.data?.json?.primaryFields[0]?.label}
            />
            <ModalCard
              title="Secondary Fields"
              value={modal.data?.json?.secondaryFields[0]?.label}
            />
            <ModalCard
              title="Auxiliary Fields"
              value={modal.data?.json?.auxiliaryFields[0]?.label}
            />
            <ModalCard
              className={'list-item p-3'}
              title="Other"
              value={modal.data?.json?.Other[0]?.label}
            />
          </>
        ) : (
          <>
            <ModalCard
              title={modal.data?.headerFields?.[0]?.label}
              value={modal.data?.headerFields?.[0]?.value}
            />
            <ModalCard
              title={modal.data?.primaryFields?.[0]?.label}
              value={modal.data?.primaryFields?.[0]?.value}
            />
            <ModalCard

              title={modal.data?.secondaryFields?.[0]?.label}
              value={modal.data?.secondaryFields?.[0]?.value}
            />
            <ModalCard

              title={modal.data?.auxiliaryFields?.[0]?.label}
              value={modal.data?.auxiliaryFields?.[0]?.value}
            />
            <ModalCard
              className={'list-item p-3'}
              title={modal.data?.Other?.[0]?.label}
              value={modal.data?.Other?.[0]?.value}
            />
          </>
        )}
      </ModalBody>
    </Modal>
  )
}

export default DataPreviewModal
