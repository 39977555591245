import React from "react";
import { Link } from "react-router-dom";
import { environment } from "utils/environment/environment";
import { goToPage } from "../../utils/functions/commonFunctions";

function Footer() {
  return (
    <div className="d-flex justify-content-between align-items-center footer w-100 flex-wrap">
      <p className="font-13 color-light-grey m-0">
      Powered by edeXa Business Blockchain © {new Date().getFullYear()}
      </p>
      <div className="d-flex">
        <Link
          to="#"
          className="font-13 color-light-grey"
          onClick={() => goToPage(environment.privacy)}
        >
          Privacy Policy
        </Link>
        <Link
          to="#"
          className="font-13 color-light-grey"
          onClick={() => goToPage(environment.tCondition)}
        >
          Terms and Conditions
        </Link>
      </div>
    </div>
  );
}

export default React.memo(Footer);
