import { Attributes, Information, Review } from "components/common";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import Stepper from "react-stepper-horizontal";
import { setLoading } from "store/loaderSlice";
import { routerLinks } from "utils/constants";
import { constants } from "utils/constants/constants";
import * as yup from "yup";
import { createSchema } from "../../actions/schema/createSchemaAPI";
import { getCredentialsStatus } from "../../actions/statusAPI";
import { getVerifiersList } from "../../actions/verifiersAPI";
import CompletedStepIcon from "../../assets/images/svg/completed-step.svg";
import { toastSuccess } from "../../utils/functions/commonFunctions";

const Credentials = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(0);
  const [verifiersList, setVerifiersList] = useState({});
  const [credentialsStatusList, setCredentialsStatusList] = useState({});
  const [isStatusFieldVisible, setIsStatusFieldVisible] = useState(false);
  const [issubmitButtonClicked, setIssubmitButtonClicked] = useState(false);
  const [isInAttributesNextButtonClicked, setIsAttributesNextButtonClicked] =
    useState(false);

  // Yup validation schema to validate form
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required("Credential Name is required")
      .max(50, "You can enter maximum 50 characters only")
      .min(3, "Credential Name should be minimum 3 char long")
      .matches(
        constants.onlyCharacterValidationRegex,
        constants.validationMessages.onlyAlphabets
      ),
    comments: yup
      .string()
      .required(constants.validationMessages.description)
      .max(250, "You can enter maximum 250 characters only")
      .min(3, "Description should be minimum 3 char long"),
    statusFlag: isStatusFieldVisible
      ? yup.string().required("Status is required")
      : yup.string(),
    trustedVerifier: yup.array().min(1).required("Please select verifier"),
    dataPoints: yup.object({
      headerFields: yup.array().of(
        yup.object().shape({
          label: yup
            .string()
            .required(constants.validationMessages.label)
            .max(50, "You can enter maximum 50 characters only")
            .min(3, "Label should be minimum 3 char long"),
          value: yup
            .string()
            .required(constants.validationMessages.description)
            .max(128, "You can enter maximum 128 characters only")
            .min(3, "Description should be minimum 3 char long"),
        })
      ),
      primaryFields: yup.array().of(
        yup.object().shape({
          label: yup
            .string()
            .required(constants.validationMessages.label)
            .max(50, "You can enter maximum 50 characters only")
            .min(3, "Label should be minimum 3 char long"),
          value: yup
            .string()
            .required(constants.validationMessages.description)
            .max(128, "You can enter maximum 128 characters only")
            .min(3, "Description should be minimum 3 char long"),
        })
      ),
      secondaryFields: yup.array().of(
        yup.object().shape({
          label: yup
            .string()
            .required(constants.validationMessages.label)
            .max(50, "You can enter maximum 50 characters only")
            .min(3, "Label should be minimum 3 char long"),
          value: yup
            .string()
            .required(constants.validationMessages.description)
            .max(128, "You can enter maximum 128 characters only")
            .min(3, "Description should be minimum 3 char long"),
        })
      ),
      auxiliaryFields: yup.array().of(
        yup.object().shape({
          label: yup
            .string()
            .required(constants.validationMessages.label)
            .max(50, "You can enter maximum 50 characters only")
            .min(3, "Label should be minimum 3 char long"),
          value: yup
            .string()
            .required(constants.validationMessages.description)
            .max(128, "You can enter maximum 128 characters only")
            .min(3, "Description should be minimum 3 char long"),
        })
      ),
      Other: yup.array().of(
        yup.object().shape({
          label: yup
            .string()
            .required(constants.validationMessages.label)
            .max(50, "You can enter maximum 50 characters only")
            .min(3, "Label should be minimum 3 char long"),
          value: yup
            .string()
            .required(constants.validationMessages.description)
            .max(128, "You can enter maximum 128 characters only")
            .min(3, "Description should be minimum 3 char long"),
        })
      ),
    }),
  });
  // test
  // validating form with formik
  const formik = useFormik({
    initialValues: {
      name: "",
      trustedVerifier: [],
      dataPoints: {
        headerFields: [{ key: "", label: "", dataType: "string", value: "" }],
        primaryFields: [{ key: "", label: "", dataType: "string", value: "" }],
        secondaryFields: [
          { key: "", label: "", dataType: "string", value: "" },
        ],
        auxiliaryFields: [
          { key: "", label: "", dataType: "string", value: "" },
        ],
        Other: [{ key: "", label: "", dataType: "string", value: "" }],
      },
      comments: "",
      statusFlag: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (issubmitButtonClicked) {
        // remove status field if status is turned off
        if (!isStatusFieldVisible) {
          //@ts-ignore
          delete values.statusFlag;
        }
        dispatch(setLoading(true));
        createSchema(values).then((response) => {
          dispatch(setLoading(false));
          navigate(routerLinks.credentials);
          toastSuccess(response?.message);
        });
      }
    },
  });
  const {
    errors,
    values,
    setFieldValue,
    validateForm,
    handleSubmit,
    handleBlur,
    touched,
  } = formik;

  // get verifierList and credentials Status list and update the states as per values
  useEffect(() => {
    // set the default verifiers of the schema
    getVerifiersList().then((response) => {
      if (response.data.length > 0) {
        setVerifiersList(response.data);
      }
    });
    // get the status list for the schema
    // user can choose the type of status from this list
    getCredentialsStatus().then((response) => {
      if (response) {
        setCredentialsStatusList(response.data);
      }
    });
  }, []);

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePrevious = () => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const checkForStepIcon = (stepIndex: number) => {
    let image = null;
    if (stepIndex <= currentStep) {
      image = CompletedStepIcon;
    }
    return image;
  };

  return (
    <div className="credentials">
      <div className="width-920 m-auto">
        <div className="custom-stepper mb-4">
          <Stepper
            steps={[
              { title: "Credential Information", icon: checkForStepIcon(0) },
              { title: "Credential Attributes", icon: checkForStepIcon(1) },
              { title: "Review Schema", icon: checkForStepIcon(2) },
            ]}
            activeStep={currentStep}
            activeColor="#007DE4"
            activeTitleColor="#0D0F12"
            completeColor="#007DE4"
            completeTitleColor="#0D0F12"
            defaultTitleColor="#0D0F12"
            titleFontSize="13px"
          />
        </div>
        <div className={currentStep !== 0 ? "d-none" : ""}>
          <Information
            errors={errors}
            values={values}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            validateForm={validateForm}
            touched={touched}
          />
          <div className="d-flex justify-content-between align-items-center account-button width-920">
            <button
              className="font-14 color-light-grey bg-transparent border-grey rounded-2 action-btn h-50px"
              onClick={handlePrevious}
              disabled
            >
              Prev
            </button>
            <button
              className="bg-blue font-14 color-white rounded-2 action-btn h-50px"
              onClick={() => {
                // call handle submit because touched property will be empty.
                // touched will be available only after handleSubmit call
                handleSubmit();
                if (!errors.name && !errors.comments) {
                  handleNext();
                }
              }}
              type="button"
            >
              Next
            </button>
          </div>
        </div>

        <div className={currentStep !== 1 ? "d-none" : ""}>
          {/* isInAttributesNextButtonClicked is used to check if user clicked on attribute's next button then and then only show the error messages */}
          <Attributes
            errors={errors}
            values={values}
            setFieldValue={setFieldValue}
            credentialsStatusList={credentialsStatusList}
            verifiersList={verifiersList}
            touched={touched}
            isInAttributesNextButtonClicked={isInAttributesNextButtonClicked}
            isStatusFieldVisible={isStatusFieldVisible}
            setIsStatusFieldVisible={setIsStatusFieldVisible}
          />
          <div className="d-flex justify-content-between align-items-center account-button width-920">
            <button
              className="font-14 color-light-grey bg-white border-grey rounded-2 action-btn h-50px"
              onClick={handlePrevious}
            >
              Prev
            </button>
            <button
              className="bg-blue font-14 color-white rounded-2 action-btn h-50px"
              onClick={() => {
                setIsAttributesNextButtonClicked(true);
                // handle next if there is no errors in the form
                validateForm();
                // if object have no errors then handle next
                if (!Object.keys(errors).length) {
                  handleNext();
                }
              }}
              type="button"
            >
              Next
            </button>
          </div>
        </div>
        <div className={currentStep !== 2 ? "d-none" : ""}>
          <Review credentialSchema={values} />
          <div className="d-flex justify-content-between align-items-center account-button width-920">
            <button
              className="font-14 color-light-grey bg-white border-grey rounded-2 action-btn h-50px"
              onClick={() => {
                setCurrentStep(0);
                // reset submit click if user clicked on edit button
                setIssubmitButtonClicked(false);
              }}
            >
              Edit
            </button>
            <button
              className="bg-blue font-14 color-white rounded-2 action-btn h-50px"
              onClick={() => {
                setIssubmitButtonClicked(true);
                handleSubmit();
              }}
              type="button"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Credentials;
