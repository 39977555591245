import { createSlice } from "@reduxjs/toolkit";

const initialState: ILoadingReducer = {
  isLoading: false,
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    }
  }
})
export const { setLoading } = loadingSlice.actions

export default loadingSlice.reducer
export interface ILoadingReducer {
  isLoading: boolean;
}
