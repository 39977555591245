import { constants } from "utils/constants/constants";
import * as Yup from "yup";

export const commomValidationSchema = {
  validationSchema: Yup.object().shape({
    firstName: Yup.string()
      .required(constants.validationMessages.firstName.require)
      .matches(
        constants.onlyCharacterValidationRegex,
        constants.validationMessages.onlyAlphabets
      ).min(3, constants.validationMessages.firstName.min).max(50, constants.validationMessages.firstName.max),
    lastName: Yup.string()
      .required(constants.validationMessages.lastName.require)
      .matches(
        constants.onlyCharacterValidationRegex,
        constants.validationMessages.onlyAlphabets
      ).min(3, constants.validationMessages.lastName.min).max(50, constants.validationMessages.lastName.max),
    email: Yup.string()
      .email(constants.validationMessages.email.required)
      .required(constants.validationMessages.email.invalid),
  }),
};