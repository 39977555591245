import { createSlice } from '@reduxjs/toolkit';
import { constants } from "utils/constants/constants";
import {
  getEncryptedCookie, handleLogout
} from "../utils/functions/commonFunctions";

const getUserToken = () => {
  const cookieUserObject = getEncryptedCookie(constants.cookieKeys.cookieUser);
  if (cookieUserObject && cookieUserObject.token) {
    return {
      isLoggedIN: true,
      unauthorized: false,
    };
  } else {
    return {
      isLoggedIN: false,
      unauthorized: false,
    };
  }
};


const authSlice = createSlice(
  {
    name: "auth",
    initialState: getUserToken(),
    reducers: {
      onUserLogIn: (state) => {
        state.isLoggedIN= true,
        state.unauthorized= false
      },
      onUserLogout: (state) => {
        state.isLoggedIN= false,
        state.unauthorized= false
        handleLogout()
      },
      logOutUserFromCurrentAppOnly: (state) => {
        state.isLoggedIN = false;
        state.unauthorized= true;
      },
    }
  })



export const { logOutUserFromCurrentAppOnly, onUserLogIn, onUserLogout } = authSlice.actions

export default authSlice.reducer

export interface IAuthReducer {
  isLoggedIN: boolean;
  userToken: null | string;
  unauthorized: boolean;
}
