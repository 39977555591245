import { GlobalErrorFallBackUI } from "core/errorBoundries";
import useGlobalSetting from "hooks/useGlobalSetting";
import { Suspense } from "react";
import { ErrorBoundary as GlobalErrorBoundary } from "react-error-boundary";
import "./App.css";
import RoutingComponent from "./core/routes/Routing";

function App() {
  //gloabl Setting custome hooks
  useGlobalSetting();

  return (
    <Suspense fallback={<h3>Loading...</h3>}>
      <GlobalErrorBoundary FallbackComponent={GlobalErrorFallBackUI}>
        <RoutingComponent />
      </GlobalErrorBoundary>
    </Suspense>
  );
}

export default App;
