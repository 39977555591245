import { memo } from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

const StringInputField = ({
  setFieldValue,
  field,
  errors,
  touched,
  values,
  isSubmitClicked,
}: any) => {
  return (
    <FormGroup className="mb-20" key={field.key}>
      <Label className="font-14 fw-bold color-black2 mb-10">
        {field.label}
      </Label>
      <Input
        type="text"
        placeholder={field.value}
        className="font-14 fw-normal color-black2 bg-white grey-border custom-input h-50px"
        name={field.key}
        onChange={(event) =>
          setFieldValue(`credentialPoint.${field.key}`, event.target.value)
        }
        value={values.credentialPoint?.[field.key] || ""}
        invalid={Boolean(
          isSubmitClicked && errors?.credentialPoint?.[field.key]
        )}
      />
      <FormFeedback>{errors?.credentialPoint?.[field.key]}</FormFeedback>
    </FormGroup>
  );
};

export default memo(StringInputField);
