import { apiEndPoint } from "../utils/constants/apiEndPoints";
import HTTPService from "../utils/httpservice";

export const getDashboardAPI = (): Promise<IgetDashboardAPIRes> => {
  return HTTPService.get(apiEndPoint.dashboardAPI);
};

export interface IrecentCredList {
  createdAt:string;
  credType:string;
  issuerBCId:string;
  issuerId:string;
  schemaBCId:string;
  schemaId:string;
  status:string;
  updatedAt:string;
  userBCId:string;
  userId:string;
  schemaData:{
    name:string;
    _id:string;
  };
  userData:{
    firstName:string;
    lastName:string;
    profilePic:string;
    _id:string;
  };
  _id:string;
}

export interface IgetDashboardAPIResData {
  holderCount:number;
  issuedCredCount:number;
  schemaCount:number;
  verifierCount:number;
  recentCredList:IrecentCredList[]
}

interface IgetDashboardAPIRes {
  message: string;
  status: number;
  data: IgetDashboardAPIResData
}
