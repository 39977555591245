import { apiEndPoint } from "../../utils/constants/apiEndPoints";
import HTTPService from "../../utils/httpservice";
import { Iparams } from "../issuersAPI";

export const getSchemaList = (params: Iparams): Promise<IgetSchemaList> => {
  return HTTPService.get(apiEndPoint.schemaListAPI, { params });
};

export interface IgetSchemaListData {
  comments: string;
  json: any;
  name: string;
  status: string;
  _id: string;
}
interface IgetSchemaList {
  status: number;
  message: string;
  count: number;
  data: IgetSchemaListData[];
}
