import { apiEndPoint } from "../../utils/constants/apiEndPoints";
import HTTPService from "../../utils/httpservice";

export const createSchema = (params: any): Promise<IcreateSchema> => {
  return HTTPService.post(apiEndPoint.schemaCreateAPI, params);
};

interface IcreateSchema {
  status: number;
  message: string;
  data: any;
}
