import { debounce } from "lodash";
import React, { FC, useCallback, useState } from "react";
import { Input } from "reactstrap";
import { constants } from "utils/constants/constants";

interface ISearchComponent {
  placeholder:string
  paginationConfig: {
    search: string;
    page: number;
    limit: number;
  };
  setPaginationConfig: React.Dispatch<
    React.SetStateAction<{
      search: string;
      page: number;
      limit: number;
    }>
  >;
}

const SearchComponent: FC<ISearchComponent> = ({
  placeholder,
  paginationConfig,
  setPaginationConfig,
}) => {
  const [search, setSearch] = useState("");

  const handleClear = () => {
    if (search !== "") {
      setSearch("");
      setPaginationConfig(constants.initialPaginationConfig);
    }
  };

  const changeSearchState = (search: string) => {
    setPaginationConfig({
      ...paginationConfig,
      search,
    });
  };

  const delayedSearch = useCallback(
    debounce(changeSearchState, constants.debounceTimeInMilliseconds),
    []
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.trim()) {
      setSearch(e.target.value);
      delayedSearch(e.target.value);
    } else {
      if (search !== "") {
        delayedSearch(e.target.value.trim());
        setSearch("");
      }
    }
  };

  return (
    <div className="filters d-flex flex-sm-nowrap w-100 w-sm-auto">
      <Input
        type="text"
        name="address"
        id="exampleAddress"
        placeholder={placeholder}
        className="w-sm-250px w-100 font-14 search-input"
        value={search}
        onChange={handleChange}
      />
      {/* <Button
        className="border-0 ml-10 bg-blue"
        type="button"
        onClick={handleClear}
      >
        Clear
      </Button> */}
      <button
        type="submit"
        className="bg-blue ml-10 color-white w-100 rounded-2 py-15 font-14"
        onClick={handleClear}
      >
        Clear
      </button>
    </div>
  );
};

export default React.memo(SearchComponent);
