import { Button, Col, Row } from "reactstrap";
import { environment } from "utils/environment/environment";

const Unauthorized = () => {
  return (
    <Row>
      <Col>
        <div className="unauthorized">
          <h2>Unauthorized!</h2>
          <h5>You are not allowed to log in to Issuer portal</h5>
          <Button
            size="sm"
            className="mt-5"
            color="primary"
            onClick={() =>
              (window.location.href = environment.accountsPortalDomain)
            }
          >
            Go to my edeXa Account
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default Unauthorized;
