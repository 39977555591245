import { LoadingTableComponent, Modal } from 'components/common'
import React, { FC, useEffect, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { Table } from 'reactstrap'
import {
  ModalTitle,
  ModalType,
  constants
} from 'utils/constants/constants'
import { getFormattedDate } from '../../utils/functions/commonFunctions'
import PaginationComponent from './PaginationComponent'
import RowPerPage from './RowPerPage'

interface ITableComponent {
  listData: any
  totalCount: number
  tableHeaders: any
  type: string
  paginationConfig: {
    search: string
    page: number
    limit: number
  }
  setPaginationConfig: React.Dispatch<
    React.SetStateAction<{
      search: string
      page: number
      limit: number
    }>
  >
}

const TableComponent: FC<ITableComponent> = ({
  listData,
  totalCount,
  tableHeaders,
  type,
  paginationConfig,
  setPaginationConfig
}) => {
  const [modal, setModal] = useState<{
    title: ModalTitle
    type: ModalType
    data: any
    isOpen: boolean
    isLoading: boolean
  }>({
    title: ModalTitle.CREDENTIALS_DETAILS,
    type: ModalType.CREDENTIALS,
    data: {},
    isOpen: false,
    isLoading: true
  })
  const [rowPerPage, setRowPerPage] = useState<number>(
    constants.rowsPerPage[0]
  )

  useEffect(() => {
    setRowPerPage(paginationConfig.limit)
  }, [paginationConfig.limit])

  const renderView = (list: any, type: any) => {
    if (type === constants.type.holders) {
      return (
        <tr key={list._id}>
          <td className="font-16 color-light-grey">{`${
            list?.firstName
              ? `${list?.firstName + ' ' + list?.lastName}`
              : `${list?.email?.split('@')[0]}`
          }`}</td>
          <td className="font-16 color-blue">
            <a href={`mailto:${list?.email}`} target={'_blank'}>
              {list?.email}
            </a>
          </td>
          <td className="font-16 color-light-grey">
            {list?.createdAt && getFormattedDate(list.createdAt)}
          </td>
        </tr>
      )
    } else if (type === constants.type.credentials) {
      console.log('fggfgg', type === constants.type.credentials)

      return (
        <tr key={list._id}>
          <td className="font-16 color-light-grey this is the class color-blue">
            <span
              className="cursor-pointer"
              onClick={() => {
                setModal({
                  data: list,
                  title: ModalTitle.CREDENTIALS_DETAILS,
                  type: ModalType.CREDENTIALS,
                  isOpen: true,
                  isLoading: true
                })
              }}
            >
              {list?.schema?.name}
            </span>
          </td>
          <td className="font-16 color-light-grey">
            {list?.user.email ? `${list?.user?.email}` : '-'}
          </td>
          <td className="font-16 color-light-grey">
            {list?.createdAt && getFormattedDate(list.createdAt)}
          </td>
        </tr>
      )
    } else if (type === constants.type.schemaList) {
      return (
        <tr key={list._id}>
          <td className="font-16 color-light-grey color-blue">
            <span
              className="cursor-pointer"
              onClick={() => {
                setModal({
                  data: list,
                  title: ModalTitle.CREDENTIALS_DETAILS,
                  type: ModalType.SCHEMA,
                  isOpen: true,
                  isLoading: false
                })
              }}
            >
              {list?.name}
            </span>
          </td>
          <td className="font-16 color-light-grey">
            {list?.comments}
          </td>
          <td className="font-16 color-light-grey pl-35 position-relative status-data">
            <span
              className={`status ${
                list.status === 'active' ? 'active' : 'deactive'
              }`}
            ></span>
            {list.status.charAt(0).toUpperCase() +
              list.status.slice(1)}
          </td>
          <td className="font-16 color-light-grey">
            {list?.createdAt && getFormattedDate(list.createdAt)}
          </td>
        </tr>
      )
    }
  }

  const calculateLastPageRange = () => {
    let lastPage = paginationConfig.limit * paginationConfig.page
    if (lastPage > totalCount) {
      return totalCount
    } else {
      return lastPage
    }
  }

  return (
    <>
      <Scrollbars
        style={{ height: 630 }}
        renderThumbHorizontal={(props: any) => (
          <div {...props} className="thumb-horizontal vertical" />
        )}
        renderThumbVertical={(props: any) => (
          <div {...props} className="thumb-vertical" />
        )}
        autoHide
        className="table-section border-grey"
      >
        <Table>
          <thead>
            <tr>
              {tableHeaders.map((header: any, index: number) => {
                return (
                  <th
                    key={index}
                    className="color-black1 font-14 fw-bold"
                  >
                    {header}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody className="table-top-border">
            {listData && listData.length > 0 ? (
              listData.map((list: any) => {
                return renderView(list, type)
              })
            ) : (
              <LoadingTableComponent />
            )}
          </tbody>
        </Table>
      </Scrollbars>
      {totalCount > 0 && (
        <div className="d-flex justify-content-between flex-wrap pagination mt-20">
          <RowPerPage
            calculateLastPageRange={calculateLastPageRange}
            paginationConfig={paginationConfig}
            rowPerPage={rowPerPage}
            setPaginationConfig={setPaginationConfig}
            setRowPerPage={setRowPerPage}
            totalCount={totalCount}
          />
          <PaginationComponent
            calculateLastPageRange={calculateLastPageRange}
            paginationConfig={paginationConfig}
            setPaginationConfig={setPaginationConfig}
            totalCount={totalCount}
          />
        </div>
      )}
      <Modal modal={modal} setModal={setModal} />
    </>
  )
}

export default React.memo(TableComponent)
