import { apiEndPoint } from "../../utils/constants/apiEndPoints";
import HTTPService from "../../utils/httpservice";
import { Iparams } from "../issuersAPI";

export const getCredentialsList = (
  params: Iparams
): Promise<IgetCredentialsList> => {
  return HTTPService.get(apiEndPoint.issueCredListAPI, { params });
};

export interface IgetCredentialsListRes {
  createdAt: string;
  schema: {
    name: string;
    _id: string;
  };

  user: {
    firstName: string;
    lastName: string;
    _id: string;
  };
  _id: string;
}
interface IgetCredentialsList {
  status: number;
  message: string;
  count: number;
  data: {
    count: number;
    request: IgetCredentialsListRes[];
  }
}
