import { MainTitle } from "components/common";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { constants } from "utils/constants/constants";
import { IgetDashboardAPIResData, getDashboardAPI } from "../../actions/dashboardAPI";
import Profile from "../../assets/images/defaultImg.png";
import Footer from "../../core/layout/Footer";
import { setLoading } from "../../store/loaderSlice";
import CountInformation from "./count/CountInformation";
import RecentlyCredentialsIssued from "./recentlyCredentialsIssued/RecentlyCredentialsIssued";
import TipsInformation from "./tips/TipsInformation";

const Dashboard = () => {
  const dispatch = useDispatch();
  
  const [data, setData] = useState<IgetDashboardAPIResData>({
    holderCount: 0,
    issuedCredCount: 0,
    schemaCount: 0,
    verifierCount: 0,
    recentCredList: [],
  });

  const handleDashboardAction = () => {
    dispatch(setLoading(true));
    getDashboardAPI().then((res) => {
      if (res.status === 200) {
        setData(res.data);
        dispatch(setLoading(false));
      }
    });
  };

  useEffect(() => {
    handleDashboardAction();
  }, []);

  return (
    <>
      <div className="dashboard">
        <MainTitle title={constants.componentName.dashboard}/>
        <CountInformation data={data} />
        <RecentlyCredentialsIssued
          Profile={Profile}
          credentialsList={data.recentCredList}
        />
        <TipsInformation />
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
