import { FC, useState } from "react";
import defaultImg from "../../assets/images/defaultImg.png";

interface IHeaderImg {
    src:string;
    width:string;
    height:string;
}

const HeaderImg:FC<IHeaderImg> = ({src, width, height}) => {
    const [error, setError] = useState(false)
    const handleError = () =>{
        setError(true)
    }
  return (
    <img src={error ? defaultImg : src} alt="profile" width={width} height={height} onError={handleError}/>
  )
}

export default HeaderImg