import { memo } from "react";
import Select from "react-select";
import { FormGroup, Label } from "reactstrap";
const SelectHolderDropdown = ({
  holdersList,
  touched,
  errors,
  setFieldValue,
}: any) => {
  let options = [];
  if (holdersList && holdersList.length) {
    options = holdersList.map((el: any) => {
      return {
        label: el.email,
        value: el.id,
      };
    });
  }
  return (
    <FormGroup>
      <Label className="font-14 fw-bold color-black2 mb-10">
        Select Holder
      </Label>
      <div className="searchable_dropdown">
        <Select
          placeholder="Select your Holder"
          options={options}
          onChange={(event: any) => setFieldValue("userId", event.value)}
          className={touched?.userId && errors?.userId ? "selectError" : ""}
        />
      </div>
      {errors?.userId && touched?.userId && (
        <div style={{ color: "#dc3545", fontSize: ".875em" }}>
          {errors?.userId}
        </div>
      )}
      {!errors?.userId && (
        <p className="font-12 color-light-grey text-right">
          Do not assign same credentials twice to the same user
        </p>
      )}
    </FormGroup>
  );
};

export default memo(SelectHolderDropdown);
