import moment from "moment";
import { memo } from "react";
import { checkIfValidDate } from "utils/functions";

// note: send type of card it can be issuer or schema
const SchemaCardPreview = ({ credentialSchema, type }:any) => {
  return type === "issuer" ? (
    <div className="right-data p-4">
      {credentialSchema?.dataPoints &&
        Object.keys(credentialSchema?.dataPoints).map((key, index) => {
          return (
            <div
              className={`${
                Object.keys(credentialSchema?.dataPoints).length === index + 1
                  ? "d-flex justify-content-end max-width-100"
                  : ""
              }`}
            >
              <p className="font-20 color-light-grey mb-20 color-light-white text-wrap max-width-100">
                {credentialSchema?.dataPoints?.[key]
                  ? (credentialSchema?.dataPoints?.[key][0].label
                      ? credentialSchema?.dataPoints?.[key][0].label + " : "
                      : "") +
                    (credentialSchema?.dataPoints?.[key][0].value
                      ? checkIfValidDate(
                          credentialSchema?.dataPoints?.[key][0].value
                        )
                        ? moment(
                            credentialSchema?.dataPoints?.[key][0].value
                          ).format("DD/MM/YYYY")
                        : credentialSchema?.dataPoints?.[key][0].value
                      : "")
                  : ""}
              </p>
            </div>
          );
        })}
    </div>
  ) : (
    <div className="right-data p-4">
      <p className="font-20 color-light-grey mb-20 color-light-white text-wrap">
        {credentialSchema?.dataPoints.hasOwnProperty("headerFields")
          ? credentialSchema?.dataPoints.headerFields[0].label
          : ""}
      </p>
      <p className="font-20 color-light-grey mb-20 color-light-white text-wrap">
        {credentialSchema?.dataPoints.hasOwnProperty("primaryFields")
          ? credentialSchema?.dataPoints.primaryFields[0].label
          : ""}
      </p>
      <p className="font-20 color-light-grey mb-20 color-light-white text-wrap">
        {credentialSchema?.dataPoints.hasOwnProperty("secondaryFields")
          ? credentialSchema?.dataPoints.secondaryFields[0].label
          : ""}
      </p>
      <div className="d-flex justify-content-end">
        <p className="font-20 color-light-grey mb-20 color-light-white text-wrap max-width-100">
          {credentialSchema?.dataPoints.hasOwnProperty("auxiliaryFields")
            ? credentialSchema?.dataPoints.auxiliaryFields[0].label
            : ""}
        </p>
      </div>
    </div>
  );
};

export default memo(SchemaCardPreview);
