import { FC, useEffect } from "react";
import { handleTitle } from "utils/functions";

interface IMainTitle {
  title: string;
}

const MainTitle: FC<IMainTitle> = ({ title }) => {
  useEffect(() => {
    handleTitle(title);
  }, []);
  return <p className="font-26 color-black1 fw-bold mb-10">{title}</p>;
};

export default MainTitle;
