import { apiEndPoint } from "../../utils/constants/apiEndPoints";
import HTTPService from "../../utils/httpservice";

export const addUserAPI = (
  params: IaddUserAPI
): Promise<IaddUserAPIResponse> => {
  return HTTPService.post(apiEndPoint.addUserAPI, params);
};

export interface IaddUserAPI {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

interface IaddUserAPIResponse {
  status: number;
  message: string;
}
