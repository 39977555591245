import { FC } from "react";
import Scrollbars from "react-custom-scrollbars";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { modulesArray } from "utils/constants/constants";

interface IModule {
  currentModule: string | undefined;
  handleModuleClick: (path: string, module: string, toggle: boolean) => void;
  pageToggle: () => void;
  dropdownPage: boolean;
}

const Module: FC<IModule> = ({
  currentModule,
  handleModuleClick,
  dropdownPage,
  pageToggle,
}) => {
  return (
    <Dropdown
      isOpen={dropdownPage}
      toggle={pageToggle}
      className="d-flex align-items-center justify-content-center left-dropdown bg-blue"
    >
      <DropdownToggle className="bg-transparent w-100 h-100 custom-class">
        {currentModule}
        <i className="fas fa-chevron-down color-white font-12"></i>
      </DropdownToggle>
      <DropdownMenu className="p-0 rounded-0 w-100 border-grey">
        <Scrollbars
          style={{ height: 245 }}
          renderThumbHorizontal={(props) => (
            <div {...props} className="thumb-horizontal" />
          )}
          renderThumbVertical={(props) => (
            <div {...props} className="thumb-vertical" />
          )}
          autoHide
          className="border-grey"
        >
          <div className="p_5">
            {modulesArray.map((module, index) => {
              return (
                <p
                  key={index}
                  className="color-dark-grey font-14 p-20 page-name"
                  onClick={() =>
                    handleModuleClick(module.path, module.label, true)
                  }
                >
                  {module.label}
                </p>
              );
            })}
          </div>
        </Scrollbars>
      </DropdownMenu>
    </Dropdown>
  );
};

export default Module;
