import { SchemaCardPreview } from "components/schemaCard";
import { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import {
  Card,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { generate5DigitRandomNumber } from "utils/functions";
import "../../style/attributes.css";
import MainTitle from "./MainTitle";
/* // the key parameter accepted by this component is the key to set the value of formik field
  ** ex: **
  dataPoints: {
    headerFields: [{label: "", dataType: ""}]
  }
  - To set the label value of the headerFields at Index 0 we will send key like this - "dataPoints.headerFields[0].dataType"
  - so now formik get the exact depth value of the key to be updated.
*/
const InputTypeSelect = ({ keyName, setFieldValue }: any) => {
  return (
    <select
      className="font-14 color-black2 form-select custom-select bg-white h-50px border-gray mb-10"
      aria-label="Default select example"
      onChange={(event) => setFieldValue(keyName, event.target.value)}
    >
      <option
        selected
        value="string"
        className="font-14 fw-normal color-black2"
      >
        String
      </option>
      <option value="date" className="font-14 fw-normal color-black2">
        Date
      </option>
      <option value="number" className="font-14 fw-normal color-black2">
        Number
      </option>
    </select>
  );
};

// key will be the exact keyName of the fieldsGroup for example -
// dataPoints: {headerFields: [{}]}
// So we will send the key name - headerFields and perform the respective operation as per necessity
// Note - ? is usefull when the Index number is not available or its undefined, like on first render
// isInAttributesNextButtonClicked - checks wheather the next button is clicked or not, if yes then show errors if there's any.
const FormGroupOfStaticInputTypes = ({
  errors,
  values,
  setFieldValue,
  LabelName,
  keyName,
  touched,
  isInAttributesNextButtonClicked,
}: any) => {
  return (
    <FormGroup className="mb-10">
      <Label className="font-14 fw-bold color-black2 mb-10">{LabelName}</Label>
      <Row>
        <Col md="12" lg="6">
          {/* label input field */}
          <Input
            type="text"
            placeholder="ex. Label"
            className="font-14 fw-normal color-black2 bg-white grey-border custom-input h-50px mb-10"
            value={values.dataPoints[keyName][0]?.label}
            onChange={(event) => {
              setFieldValue(
                `dataPoints.${keyName}[0].key`,
                event.target.value + generate5DigitRandomNumber()
              );
              setFieldValue(
                `dataPoints.${keyName}[0].label`,
                event.target.value
              );
            }}
            invalid={
              errors?.dataPoints?.hasOwnProperty(keyName) &&
              Boolean(errors?.dataPoints?.[keyName]?.[0]?.label) &&
              Boolean(touched?.dataPoints?.[keyName]?.[0]?.label) &&
              isInAttributesNextButtonClicked
            }
          />
          <FormFeedback className="mb-3">
            {errors?.dataPoints?.hasOwnProperty(keyName) &&
              errors?.dataPoints?.[keyName]?.[0]?.label}
          </FormFeedback>
        </Col>
        <Col md="12" lg="6" className="padding-col">
          {/* dataType input field */}
          <InputTypeSelect
            keyName={`dataPoints.${keyName}[0].dataType`}
            setFieldValue={setFieldValue}
          />
        </Col>
      </Row>
      {/* description input field */}
      <Input
        type="text"
        placeholder="Description"
        className="font-14 fw-normal color-black2 bg-white grey-border custom-input h-50px mb-10"
        onChange={(event) =>
          setFieldValue(`dataPoints.${keyName}[0].value`, event.target.value)
        }
        value={values.dataPoints?.[keyName]?.[0]?.value}
        invalid={
          errors?.dataPoints?.hasOwnProperty(keyName) &&
          Boolean(errors?.dataPoints?.[keyName]?.[0]?.value) &&
          Boolean(errors?.dataPoints?.[keyName]?.[0]?.value) &&
          isInAttributesNextButtonClicked
        }
      />
      <FormFeedback className="mb-3">
        {errors?.dataPoints?.hasOwnProperty(keyName)
          ? errors?.dataPoints?.[keyName]?.[0]?.value
          : null}
      </FormFeedback>
    </FormGroup>
  );
};

// key will be the exact keyName of the fieldsGroup for example -
// dataPoints: {headerFields: [{}]}
// So we will send the key name - headerFields and perform the respective operation as per necessity
// ** It supports AddNew and Remove Field feature.
const FormGroupOfDynamicInputTypes = ({
  errors,
  values,
  setFieldValue,
  LabelName,
  keyName,
  isInAttributesNextButtonClicked,
}: any) => {
  return values.dataPoints[keyName].map((currentField: any, index: number) => {
    return (
      <FormGroup className="mb-10" key={index}>
        <div className="font-14 fw-bold color-black2 mb-10 w-100 d-flex justify-content-between align-items-center">
          {/* enable this feature when we need the "Add New Field feature" */}
          {index === 0 ? LabelName : " "}
          {/* {index === 0 ? (
            <button
              className="color-blue font-14 fw-bold bg-transparent"
              onClick={() => handleAddNewFieldValue(keyName)}
            >
              Add new
            </button>
          ) : (
            <button
              className="font-14 fw-bold bg-transparent text-danger"
              onClick={() => handleRemoveFieldValue(keyName, index)}
            >
              Remove
            </button>
          )} */}
        </div>
        <Row>
          <Col md="12" lg="6">
            {/* Label input type field */}
            <Input
              type="text"
              placeholder="ex. Label"
              className="font-14 fw-normal color-black2 bg-white grey-border custom-input h-50px mb-10"
              value={values.dataPoints[keyName]?.[index]?.label}
              onChange={(event) => {
                setFieldValue(
                  `dataPoints.${keyName}[${index}].key`,
                  event.target.value + generate5DigitRandomNumber()
                );
                setFieldValue(
                  `dataPoints.${keyName}[${index}].label`,
                  event.target.value
                );
              }}
              invalid={
                errors?.dataPoints?.hasOwnProperty(keyName) &&
                Boolean(errors?.dataPoints?.[keyName]?.[index]?.label) &&
                Boolean(errors?.dataPoints?.[keyName]?.[index]?.label) &&
                isInAttributesNextButtonClicked
              }
            />
            <FormFeedback className="mb-3">
              {errors?.dataPoints?.hasOwnProperty(keyName)
                ? errors?.dataPoints?.[keyName]?.[index]?.label
                : null}
            </FormFeedback>
          </Col>
          <Col md="12" lg="6" className="padding-col">
            {/* dataType select field */}
            <InputTypeSelect
              keyName={`dataPoints.${keyName}[${index}].dataType`}
              setFieldValue={setFieldValue}
            />
          </Col>
        </Row>
        {/* description input field */}
        <Input
          type="text"
          placeholder="Description"
          className="font-14 fw-normal color-black2 bg-white grey-border custom-input h-50px mb-10"
          value={values.dataPoints[keyName]?.[index]?.value}
          onChange={(event) =>
            setFieldValue(
              `dataPoints.${keyName}[${index}].value`,
              event.target.value
            )
          }
          invalid={
            errors?.dataPoints?.hasOwnProperty(keyName) &&
            Boolean(errors?.dataPoints?.[keyName]?.[index]?.value) &&
            Boolean(errors?.dataPoints?.[keyName]?.[index]?.value) &&
            isInAttributesNextButtonClicked
          }
        />
        <FormFeedback className="mb-3">
          {errors?.dataPoints?.hasOwnProperty(keyName)
            ? errors?.dataPoints?.[keyName]?.[index]?.value
            : null}
        </FormFeedback>
      </FormGroup>
    );
  });
};

const defaultInitialFieldValues = {
  key: "",
  label: "",
  dataType: "string",
  value: "",
};

const Attributes = ({
  errors,
  values,
  setFieldValue,
  credentialsStatusList,
  verifiersList,
  touched,
  isInAttributesNextButtonClicked,
  isStatusFieldVisible,
  setIsStatusFieldVisible,
}: any) => {
  // multiple select visible
  const [isMultipleSelectVisible, setIsMultipleSelectvisible] = useState(false);
  const [selectedVerifiers, setSelectedVerifiers] = useState([]);

  useEffect(() => {
    setFieldValue("trustedVerifier", selectedVerifiers);
  }, [selectedVerifiers, setFieldValue]);

  // handle event when user click on addNew
  const handleAddNewFieldValue = (fieldName: any) => {
    // push new empty field to the respecitve fieldsArray
    const newFields = [
      ...values.dataPoints[fieldName],
      defaultInitialFieldValues,
    ];
    setFieldValue(`dataPoints.${fieldName}`, newFields);
  };
  // handle event when user click on Remove
  const handleRemoveFieldValue = (fieldName: any, index: any) => {
    values.dataPoints[fieldName].splice(index, 1);
    setFieldValue(`dataPoints.${fieldName}`, values.dataPoints[fieldName]);
  };

  // if status field visible is hidden then set it to null
  useEffect(() => {
    if (isStatusFieldVisible === false) {
      setFieldValue("statusFlag", null);
    }
  }, [isStatusFieldVisible]);

  return (
    <>
      <div className="m-auto bg-white rounded-10 p-20">
        <MainTitle title="Credential Attributes" />
        <p className="font-14 color-light-grey mb-20">
          {/* Some info may be visible to other people using Google service. */}
          Create your dynamic credentials on the go.
        </p>
        <Row>
          <Col sm="12" lg="6" className="left-data">
            <Scrollbars
              style={{ height: 520 }}
              renderThumbHorizontal={(props) => (
                <div
                  {...props}
                  className="thumb-horizontal"
                  style={{ display: "none" }}
                />
              )}
              renderThumbVertical={(props) => (
                <div {...props} className="thumb-vertical" />
              )}
              autoHide
              className="credential-scroll"
            >
            <form className="mb-20" style={{ padding: 15 }}>
              {/* Header Fields */}
              <FormGroupOfStaticInputTypes
                setFieldValue={setFieldValue}
                errors={errors}
                values={values}
                keyName={"headerFields"}
                LabelName={"Header Fields"}
                touched={touched}
                isInAttributesNextButtonClicked={
                  isInAttributesNextButtonClicked
                }
              />
              {/* Primary Fields */}
              <FormGroupOfStaticInputTypes
                setFieldValue={setFieldValue}
                errors={errors}
                values={values}
                keyName={"primaryFields"}
                LabelName={"Primary Fields"}
                touched={touched}
                isInAttributesNextButtonClicked={
                  isInAttributesNextButtonClicked
                }
              />
              {/* dynamic form group elements */}
              {/* secondary Fields */}
              <FormGroupOfDynamicInputTypes
                setFieldValue={setFieldValue}
                errors={errors}
                values={values}
                keyName={"secondaryFields"}
                LabelName={"Secondary Fields"}
                handleRemoveFieldValue={handleRemoveFieldValue}
                handleAddNewFieldValue={handleAddNewFieldValue}
                touched={touched}
                isInAttributesNextButtonClicked={
                  isInAttributesNextButtonClicked
                }
              />
              {/* auxiliary Fields */}
              <FormGroupOfDynamicInputTypes
                setFieldValue={setFieldValue}
                errors={errors}
                values={values}
                keyName={"auxiliaryFields"}
                LabelName={"Auxiliary Fields"}
                handleRemoveFieldValue={handleRemoveFieldValue}
                handleAddNewFieldValue={handleAddNewFieldValue}
                touched={touched}
                isInAttributesNextButtonClicked={
                  isInAttributesNextButtonClicked
                }
              />
              {/* Other Fields */}
              <FormGroupOfDynamicInputTypes
                setFieldValue={setFieldValue}
                errors={errors}
                values={values}
                keyName={"Other"}
                LabelName={"Other Fields"}
                handleRemoveFieldValue={handleRemoveFieldValue}
                handleAddNewFieldValue={handleAddNewFieldValue}
                touched={touched}
                isInAttributesNextButtonClicked={
                  isInAttributesNextButtonClicked
                }
              />
              {/* handle expiration */}
              {/* <Row className="align-items-center">
                <Col sm="12" lg="5">
                  <div className="d-flex align-items-center">
                    <p
                      className="font-14 color-black2 fw-bold"
                      style={{ width: 65, color: "#4c4f539e" }}
                    >
                      Expiration
                    </p>
                    <input
                      className="react-switch-checkbox"
                      id="expiration"
                      type="checkbox"
                      checked={false}
                      onChange={(event) =>
                        setFieldValue("expiration", event.target.checked)
                      }
                    />
                    <label className="react-switch-label" htmlFor="expiration">
                      <span className={`react-switch-button`} />
                    </label>
                  </div>
                </Col>

              </Row> */}
                {/* Credential Form Status */}
              <Row className="mb-20 align-items-center mt-3">
                <Col sm="12" lg="5">
                  <div className="d-flex align-items-center">
                    <p
                      className="font-14 color-black2 fw-bold"
                      style={{ width: 65 }}
                    >
                      Status
                    </p>
                    <input
                      className="react-switch-checkbox"
                      id="status"
                      type="checkbox"
                      onChange={(event) =>
                        setIsStatusFieldVisible(event.target.checked)
                      }
                    />
                    <label className="react-switch-label" htmlFor="status">
                      <span className={`react-switch-button`} />
                    </label>
                  </div>
                </Col>
                <Col sm="12" lg="7">
                  <select
                    className={`font-14 color-black2 form-select custom-select bg-white h-50px border-gray mt-10 ${
                      isStatusFieldVisible ? "d-block" : "d-none"
                    }`}
                    aria-label="Default select example"
                    name="statusFlag"
                    onChange={(event) =>
                      setFieldValue("statusFlag", event.target.value)
                    }
                    value={values.statusFlag || ""}
                  >
                    <option
                      selected
                      disabled
                      className="font-14 fw-normal color-black2"
                      value=""
                    >
                      Select Status
                    </option>
                    {credentialsStatusList?.length > 0 && (
                      <>
                        {credentialsStatusList.map((status: any) => {
                          return (
                            <option
                              value={status.type}
                              className="font-14 fw-normal color-black2"
                              key={status.type}
                            >
                              {status.list.map((list: any, index: number) => {
                                return index === 0
                                  ? list.label + "-"
                                  : list.label;
                              })}
                            </option>
                          );
                        })}
                      </>
                    )}
                  </select>
                </Col>
                <FormFeedback
                  style={{
                    display:
                      errors.statusFlag &&
                      touched.statusFlag &&
                      isInAttributesNextButtonClicked
                        ? "block"
                        : "none",
                  }}
                >
                  Please select the status
                </FormFeedback>
              </Row>
              {/* Select Verifiers radio buttons */}
              <div className="pb-20">
                <div className="d-flex w-100">
                  <Label
                    className="font-14 fw-bold color-black2"
                    style={{ width: 130 }}
                  >
                    Select Verifiers
                  </Label>
                  <FormGroup check className="custom-radio mr-30">
                    <Label
                      check
                      className="position-relative font-14 fw-normal color-black2 d-flex align-items-center pl-25"
                    >
                      <Input
                        type="radio"
                        name="verifiers"
                        className="me-2 font-20 mt-0"
                        // @ts-ignore
                        value={false}
                        onChange={() => {
                          setIsMultipleSelectvisible(false);
                          // if all selected then set trustedVerifier to have all the verifiers list
                          setFieldValue(
                            "trustedVerifier",
                            // @ts-ignore
                            verifiersList.map((verifier) => verifier.id)
                          );
                        }}
                      />{" "}
                      <span className="outer-circle"></span>
                      All
                    </Label>
                  </FormGroup>
                  <FormGroup check className="custom-radio">
                    <Label
                      check
                      className={`position-relative font-14 fw-normal color-black2 d-flex align-items-center pl-25`}
                    >
                      <Input
                        type="radio"
                        name="verifiers"
                        className="me-2 font-20 mt-0"
                        // @ts-ignore
                        value={true}
                        onChange={() => {
                          setIsMultipleSelectvisible(true);
                        }}
                      />{" "}
                      <span className="outer-circle"></span>
                      Custom
                    </Label>
                  </FormGroup>
                </div>
                <FormFeedback
                  style={{
                    display:
                      errors.trustedVerifier &&
                      touched.trustedVerifier &&
                      isInAttributesNextButtonClicked
                        ? "block"
                        : "none",
                  }}
                >
                  Please select a verifier
                </FormFeedback>
                {/* Verifiers list component */}
                <Row className="my-3">
                  <Col>
                    <FormGroup
                      className={`${
                        isMultipleSelectVisible
                          ? "d-flex flex-column"
                          : "d-none"
                      }`}
                    >
                      <Card
                        style={{ marginRight: "20px", marginBottom: "20px" }}
                      >
                        <div className="verifier-header d-flex justify-content-between align-items-center p-3 border-bottom">
                          <p className="fw-bold font-14">
                            {selectedVerifiers?.length} verifier
                            {selectedVerifiers?.length > 1 && "s"} selected
                          </p>
                          {selectedVerifiers.length > 0 ? (
                            <p
                              className="color-blue fw-bold font-14"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setSelectedVerifiers([]);
                              }}
                            >
                              Deselect All
                            </p>
                          ) : (
                            <p
                              className="color-blue fw-bold font-14"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                let entries = [];
                                for (let i = 0; i < verifiersList.length; i++) {
                                  entries.push(verifiersList[i].id);
                                }
                                // @ts-ignore
                                setSelectedVerifiers(entries);
                              }}
                            >
                              Select All
                            </p>
                          )}
                        </div>
                        <div className="verifier-list d-flex flex-column">
                          {verifiersList?.length > 0 && (
                            <>
                              {verifiersList.map((verifier: any) => {
                                return (
                                  <div className="py-2 px-3 bg-gray border-bottom verifier-item">
                                    <Input
                                      type="checkbox"
                                      value={verifier.id}
                                      key={verifier.id}
                                      style={{ marginRight: "10px" }}
                                      checked={selectedVerifiers.find(
                                        (el) => el === verifier.id
                                      )}
                                      onClick={(event) => {
                                        // if current event is checked then add new value to array otherwise remove that value from array
                                        // and handle the checkbox state as per the event
                                        // @ts-ignore
                                        if (event.target.checked) {
                                          // @ts-ignore
                                          setSelectedVerifiers([
                                            // @ts-ignore
                                            ...selectedVerifiers,
                                            // @ts-ignore
                                            event.target.value,
                                          ]);
                                        } else {
                                          const filteredResult =
                                            selectedVerifiers.filter(
                                              // @ts-ignore
                                              (el) => el !== event.target.value
                                            );
                                          setSelectedVerifiers(filteredResult);
                                        }
                                      }}
                                    />
                                    <Label className="font-14">
                                      {verifier.email}
                                    </Label>
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </Card>
                    </FormGroup>
                    <FormFeedback>{errors.trustedVerifier}</FormFeedback>
                  </Col>
                </Row>
              </div>
            </form>
            </Scrollbars>
          </Col>
          {/* right side card preview */}
          <Col sm="12" lg="6">
            <SchemaCardPreview credentialSchema={values} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Attributes;
