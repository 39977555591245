import { apiEndPoint } from "../utils/constants/apiEndPoints";
import HTTPService from "../utils/httpservice";

export const getCredentialsStatus = (params?: {
  page: number;
  limit: number;
  search: string;
}) => {
  return HTTPService.get(apiEndPoint.getStatusListAPI, { params });
};
