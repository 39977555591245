import { constants } from "utils/constants/constants";
import { getEncryptedCookie } from "../../utils/functions/commonFunctions";
import { getUserInfo } from "../common/authAPI";

export const getUserDetails = () => {
  const cookieUserObject = getEncryptedCookie(constants.cookieKeys.cookieUser);
  if (cookieUserObject.token) {
    getUserInfo(cookieUserObject.token).then(() => {
      window.location.reload();
    });
  }
};
  