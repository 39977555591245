import { getGlobalSettingAPI } from 'actions/common'
import useAuthCookieWatcher from 'hooks/useCookieWatcher'
import { Suspense, useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import { setGlobalSetting } from 'store/globalSettingSlice'
import { IRootReducer } from 'store/rootRededucer'
import { constants } from 'utils/constants'
import { getUserInfo } from '../../actions/common/authAPI'
import {
  logOutUserFromCurrentAppOnly,
  onUserLogIn,
  onUserLogout
} from '../../store/authSlice'
import { onUserDetails } from '../../store/userSlice'
import {
  getDecryptedLocalStorage,
  getEncryptedCookie,
  handleErrors,
  setEncryptedLocalStorage
} from '../../utils/functions/commonFunctions'
import PrivateRouteComponent from './ProtectedRouteComponent'
import PublicRouteComponent from './PublicRouteComponent'
import UnauthorizedRouteComponent from './UnauthorizedRouteComponent'
import UnderMaintenaceRouteComponent from './UnderMaintenaceRouteComponent'

function RoutingComponent() {
  handleErrors()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const { isLoading } = useSelector(
    (state: IRootReducer) => state.loading
  )
  const { isUnderMaintenance } = useSelector(
    (state: IRootReducer) => state.globalSetting
  )


  const auth = useSelector((state: IRootReducer) => state.auth)

  const getUser = (token: string) => {
    setLoading(true)
    getUserInfo(token)
      .then((res: any) => {
        setEncryptedLocalStorage(
          constants.localStorageKeys.userToken,
          token
        )
        setLoading(false)
        dispatch(onUserDetails(JSON.stringify(res.data)))
        dispatch(onUserLogIn())
        setEncryptedLocalStorage(
          constants.localStorageKeys.userInfo,
          res.data
        )
      })
      .catch((error) => {
        setLoading(false)
        if (error.status === 401) {
          dispatch(logOutUserFromCurrentAppOnly())
        }
      })
  }

  const getUnderMaintanance = () => {
    getGlobalSettingAPI().then((res: any) => {
      if (res.status === 200) {
        dispatch(setGlobalSetting(res.data))
      }
    })
  }

  useEffect(() => {
    getUnderMaintanance()
  }, [])

  useEffect(() => {
    const cookieUserObject = getEncryptedCookie(
      constants.cookieKeys.cookieUser
    )
    const userLocalToken = getDecryptedLocalStorage(
      constants.localStorageKeys.userToken
    )
    if (cookieUserObject && cookieUserObject.token) {
      if (
        !getDecryptedLocalStorage(constants.localStorageKeys.userInfo)
      ) {
        getUser(cookieUserObject.token)
      } else if (userLocalToken !== cookieUserObject.token) {
        getUser(cookieUserObject.token)
      } else {
        dispatch(onUserLogIn())
      }
    } else {
      // check if its a public verification page, else make user logout
      if (!window.location.pathname.includes('public-verification')) {
        dispatch(onUserLogout())
      }
    }
  }, [])

  // check if user auth cookie changes/deleted then logout him from current app.
  // ⚠ Be cautious while using this hook because it can keep your user logged-out in loop
  const { exist } = useAuthCookieWatcher(
    `${constants.cookieKeys.cookieInitial}-${constants.cookieKeys.cookieUser}`,
    1000
  )

  useEffect(() => {
    // if the user is logged in but the auth cookie dosen't exist that means he is logged out from some other subdomains so logout him from current app too.
    if (!exist && auth?.isLoggedIN) {
      dispatch(onUserLogout())
    }
  }, [exist, auth?.isLoggedIN])

  return (
    <>
      {isLoading ? (
        <div className="loader-wrapper">
          <Spinner type="grow" color="light" />
        </div>
      ) : null}
      {loading ? (
        <div className="loader-wrapper">
          <Spinner type="grow" color="light" />
        </div>
      ) : null}
      <Suspense fallback={<p>Loading...</p>}>
        <Toaster />
        {isUnderMaintenance?.isUnderMaintenance === 1 ? (
          <UnderMaintenaceRouteComponent />
        ) : !loading && !auth?.unauthorized ? (
          auth?.isLoggedIN ? (
            <PrivateRouteComponent />
          ) : (
            <PublicRouteComponent />
          )
        ) : null}
        {auth?.unauthorized ? <UnauthorizedRouteComponent /> : null}
      </Suspense>
    </>
  )
}

export default RoutingComponent
