import { apiEndPoint } from 'utils/constants'
import HTTPService from 'utils/httpservice'

export const getGlobalSettingAPI =
  (): Promise<IgetGlobalSettingAPI> => {
    return HTTPService.get(apiEndPoint.globalSetting)
  }

export interface IgetGlobalSettingAPIData {
  isUnderMaintenance: any
}

interface IgetGlobalSettingAPI {
  message: string
  status: number
  data: IgetGlobalSettingAPIData
}
