import { FC } from 'react'

interface IModalCard {
  title: string
  value: string
  className?: string
}

const ModalCard: FC<IModalCard> = ({ title, value, className }) => {
  return (
    <div className={className ? className :"list-item border-bottom p-3"}>
      <p className="font-20">{title}</p>
      <p className="font-16 color-light-grey fw-bold mt-1">{value}</p>
    </div>
  )
}

export default ModalCard
