import { AES, enc } from "crypto-js";
import { isString } from "lodash";
import moment from "moment";
import toast from "react-hot-toast";
import { environment } from "utils/environment";
import { constants } from "../constants/constants";

const removeDecryptedCookie = (key: string) => {
  if (key) {
    const keyName = constants.cookieKeys.cookieInitial + "-" + key.trim();
    document.cookie = `${keyName}=;expires=${new Date(
      0
    ).toUTCString()};domain=${window.location.hostname.replace(
      "byou-issuer",
      ""
    )};path=/;`;
  }
};

export const toastSuccess = (message: string) => {
  toast.remove();
  toast.success(message, {
    // @ts-ignore
    position: constants.toasterPosition,
    style: {
      color: "#000",
      minWidth: 150,
      padding: 10,
      fontWeight: 500,
      marginBottom: 60,
      border: "1px solid #073E84",
    },
    iconTheme: { primary: "#073E84 ", secondary: "#fff" },
  });
};

export const toastError = (message: string) => {
  toast.remove();
  toast.error(message, {
    // @ts-ignore
    position: constants.toasterPosition,
    style: {
      color: "#000",
      fontWeight: 500,
      padding: 10,
      marginBottom: 60,
      border: "1px solid #ff0000",
    },
  });
};

const getCookie = (cookieName: any) => {
  let name = cookieName + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  if (decodedCookie) {
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      while (c.charAt(0) === "") {
        c = c.substring(1);
      }
      if (+c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return "";
};

export const decryptData = (data: any) => {
  const bytes = AES.decrypt(
    data.toString(),
    constants.cookieKeys.cryptoSecretKey
  );
  if (bytes.toString()) {
    return JSON.parse(bytes.toString(enc.Utf8));
  }
};

export const getEncryptedCookie = (key: string) => {
  if (key) {
    const keyName = constants.cookieKeys.cookieInitial + "-" + key.trim();
    const cookieData = getCookie(keyName);
    if (cookieData) {
      return decryptData(cookieData);
    }
  }
};

export const handleLogout = () => {
  localStorage.clear();
  removeDecryptedCookie(constants.cookieKeys.cookieUser);
  window.location.href = environment.accountLogin || "";
};

export const encryptData = (data: any) => {
  return AES.encrypt(JSON.stringify(data), constants.cookieKeys.cryptoSecretKey);
};

export const setEncryptedLocalStorage = (key: string, data: any) => {
  if (data && key) {
    const encryptedString = encryptData(data);
    const keyName = constants.cookieKeys.cookieInitial + "-" + key.trim();
    localStorage.setItem(keyName, encryptedString.toString());
  }
};

export const getDecryptedLocalStorage = (key: string) => {
  if (key) {
    const keyName = constants.cookieKeys.cookieInitial + "-" + key.trim();
    const localStorageData = localStorage.getItem(keyName);
    if (localStorageData) {
      return decryptData(localStorageData);
    } else {
      const cookieUser = getEncryptedCookie(constants.cookieKeys.cookieUser);
      if (!cookieUser) {
        removeDecryptedCookie(constants.cookieKeys.cookieUser);
      }
    }
  }
};

export const setEncryptedSessionStorage = (key: string, data: any) => {
  if (data && key) {
    const encryptedString = encryptData(data);
    const keyName = constants.cookieKeys.cookieInitial + "-" + key.trim();
    sessionStorage.setItem(keyName, encryptedString.toString());
  }
};

export const getDecryptedSessionStorage = (key: string) => {
  if (key) {
    const keyName = constants.cookieKeys.cookieInitial + "-" + key.trim();
    const sessionStorageData = sessionStorage.getItem(keyName);
    if (sessionStorageData) {
      return decryptData(sessionStorageData);
    }
    return null
  }
};

export const getFormattedDate = (utcDate: string) => {
  return moment(utcDate).format(constants.dateFormat);
};

export const checkIfValidDate = (value: any) => {
  // if its a string, not a date we return false to show the actual value itself
  if (isString(value)) {
    return false;
  } else {
    return moment(new Date(value)).isValid();
  }
};

export const generate5DigitRandomNumber = () => {
  return Math.floor(Math.random() * 90000) + 10000;
};

export const getMinDateTomorrow = () => {
  return (
    new Date().getFullYear().toString() +
    "-" +
    (new Date().getMonth() + 1).toString() +
    "-" +
    (new Date().getDate() + 1).toString()
  );
};

export const goToPage = (url?: string) => {
  window.open(url, "_blank");
};


export const handleTitle = (meta: string) => {
  const name = `${meta.charAt(0).toUpperCase() + meta.slice(1)
    } | Issuer Portal`;
  document.title = name;
};

export const triggerComingSoon = () => {
  toastSuccess(constants.toasterMessages.comingSoon);
};

export const handleEmptyObject = (paginationConfig: {
  page: number;
  limit: number;
  search?: string;
}) => {
  let data: { page: number; limit: number; search?: string } = {
    page: 1,
    limit: 10,
  };
  if (paginationConfig.search) {
    data = paginationConfig;
  } else {
    if (paginationConfig.page) {
      data.page = paginationConfig.page;
    }
    data.limit = paginationConfig.limit;
  }
  return data;
};

export const handleTrim = (value: string, name: string, handleChange: any) => {
  if (value.trim()) {
    handleChange(name, value)
  } else {
    handleChange(name, "")
  }
}

export const handleErrors = () => {
  if (environment.appStatus === "PRODUCTION" || environment.appStatus === "STAGING") {
    console.log = () => { };
    console.error = () => { };
    console.debug = () => { };
    console.warn = () => { };
  }
};
