import { SubTitle } from "components/common";
import { memo } from "react";
import { Col } from "reactstrap";

const DashboardTipsComponent = (props: IDashboardTips) => {
  const { title, paragraph, btnName, btnClick } = props;
  return (
    <Col sm="12" md="6" lg="3" className="tip">
      <div className="tips-box border-grey rounded-3">
        <div className="mb-10">
          <SubTitle title={title} />
        </div>
        <p className="font-16 color-light-grey mb-10">{paragraph}</p>
        <span
          className="font-16 fw-bold color-blue cursor-pointer"
          onClick={btnClick}
        >
          {btnName}
        </span>
      </div>
    </Col>
  );
};

export default memo(DashboardTipsComponent);

interface IDashboardTips {
  title: string;
  paragraph: string;
  btnName: string;
  btnClick: (e: any) => void;
}
