import { createSlice } from '@reduxjs/toolkit';
import { IgetGlobalSettingAPIData } from 'actions/common/getGlobalSettingAPI';
import { constants } from "utils/constants";
import { getDecryptedSessionStorage } from "utils/functions";

const initialState: IgetGlobalSettingAPIData = {
  isUnderMaintenance: getDecryptedSessionStorage(constants.sessionStorageKeys.globalSetting)?.isUnderMaintenance || 0,
};

const globalSettingSlice = createSlice(
  {
    name: "global",
    initialState,
    reducers: {
      setGlobalSetting: (state, action) => {
        state.isUnderMaintenance = action.payload;
      }
    }
  }
);

export const { setGlobalSetting } = globalSettingSlice.actions

export default globalSettingSlice.reducer;
