import { createCredential } from "actions/credentials";
import { getHolderAndVerifierList } from "actions/schema";
import { DateInputField, MainTitle, StringInputField } from "components/common";
import { SelectHolderDropdown } from "components/holders";
import { SchemaCardPreview, SelectSchemaDropdown } from "components/schemaCard";
import { Footer } from "core/layout";
import "flatpickr/dist/themes/material_blue.css";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import Flatpickr from "react-flatpickr";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { setLoading } from "store/loaderSlice";
import { routerLinks } from "utils/constants";
import { constants } from "utils/constants/constants";
import { getMinDateTomorrow, toastSuccess } from "utils/functions";
import * as yup from "yup";

// constant to set by default expiry date of credential
const todayDate = new Date();
const tommorowDate = new Date(todayDate.setDate(todayDate.getDate() + 1));

const IssueCredential = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [schemaList, setSchemaList] = useState(null);
  const [holdersList, setHoldersList] = useState(null);
  const [isSubmitClicked, setIssubmitClicked] = useState(false);
  const [selectedSchema, setSelectedSchema] = useState<any>(null);
  const [expiryDateVisible, setExpiryDateVisible] = useState(false);
  const [currentSchemaStatus, setCurrentSchemaStatus] = useState<any>({});

  useEffect(() => {
    dispatch(setLoading(true));
    getHolderAndVerifierList().then((response: any) => {
      setHoldersList(response.data.holders);
      setSchemaList(response.data.schemas);
      dispatch(setLoading(false));
    });
  }, [dispatch]);

  // validation schema
  // validation schema for data points
  const designValidationSchemaForDataPoints = (initialFormState: any) => {
    if (initialFormState) {
      if (Object.keys(initialFormState).length > 0) {
        let validationObject: any = {};
        for (let [mainKey] of Object.entries(initialFormState)) {
          // eslint-disable-next-line array-callback-return
          initialFormState?.[mainKey].map((item: any) => {
            if (item.dataType === "string") {
              validationObject[item.key] = yup
                .string()
                .required(`${item.label} is required`)
                // .matches(
                //   constants.onlyCharacterValidationRegex,
                //   constants.validationMessages.onlyAlphabets
                // );
            } else if (item.dataType === "number") {
              validationObject[item.key] = yup
                .string()
                .required(`${item.label} is required`)
                .matches(
                  constants.onlyNumberRegex,
                  constants.validationMessages.onlyNumerical
                );
              // .number("Please enter a numeric value")
            } else if (item.dataType === "date") {
              validationObject[item.key] = yup
                .string()
                .required(`${item.label} is required`);
            }
          });
        }
        return yup.object().shape(validationObject);
      } else {
        return null;
      }
    }
    return null;
  };

  const validationSchema = yup.object().shape({
    credType: yup.string().required("Credential Type is Required"),
    // @ts-ignore
    credentialPoint: designValidationSchemaForDataPoints(selectedSchema),
    credentialSchemaId: yup.string().required("Please select a schema"),
    statusFlag:
      currentSchemaStatus &&
      yup.object({
        type: yup.number().required("Status is required"),
        value: yup.string().required("Please select a status"),
      }),
    userId: yup.string().required("Please select a holder"),
  });

  const formik = useFormik({
    initialValues: {
      credType: "internal",
      credentialPoint: {},
      credentialSchemaId: "",
      expiration: tommorowDate,
      statusFlag: {
        type: null,
        value: "",
      },
      userId: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      let data: any = {};
      if (!values.statusFlag.values) {
        data.credType = values.credType;
        data.credentialPoint = values.credentialPoint;
        data.credentialSchemaId = values.credentialSchemaId;
        data.userId = values.userId;
      } else {
        data = values;
      }
      dispatch(setLoading(true));
      if (!expiryDateVisible) delete values.expiration; // delete expiration date property if schema have no expiration date
      createCredential(data).then((response: any) => {
        dispatch(setLoading(false));
        navigate(routerLinks.issuecredential);
        toastSuccess(response?.message);
      });
    },
  });
  const { errors, values, handleSubmit, touched, setFieldValue } = formik;

  const handleOnSchemaChange = (values: any) => {
    // set current selected schema's status type
    setCurrentSchemaStatus(values?.statusFlag);
    setFieldValue("statusFlag.type", values?.statusFlag?.type);

    // set schema id on schema change
    const schemaId = values?._id;
    setFieldValue("credentialSchemaId", schemaId);

    // set current selected schema
    // convert stringify value to json
    // after that we will render these fields
    setSelectedSchema(values?.json);

    // set expiration to null if schema.expiration is false
    if (values.expiration === false) {
      setFieldValue("expiration", null); // expiry to be null if schema don't have expiry true
      setExpiryDateVisible(false);
    } else {
      setExpiryDateVisible(true);
      setFieldValue("expiration", tommorowDate);
    }
  };

  // 1. In this function we loop through the values of respective key of currently selected schema
  // 2. Then we get the state value of that particular key from current formik form state.
  // 3. We need these values to be shown in the card preview
  // 4. This validation make sure that we show only particular values at their respective place [headerFields, secondaryFields, primaryFields, auxiliaryFields]
  const getCurrentFieldValue = (keyName: any) => {
    let valueOfField = {
      value: "",
      label: "",
    };
    // @ts-ignore
    // eslint-disable-next-line array-callback-return
    selectedSchema?.[keyName]?.map((item: any) => {
      // @ts-ignore
      valueOfField.value = values.credentialPoint?.[item.key];
      valueOfField.label = item?.label;
    });
    valueOfField.value?.toString();
    return valueOfField;
  };

  // schema card preview data formatting
  const credentialSchema = {
    dataPoints: {
      headerFields: [
        {
          label: getCurrentFieldValue("headerFields")?.label,
          value: getCurrentFieldValue("headerFields")?.value,
        },
      ],
      primaryFields: [
        {
          label: getCurrentFieldValue("primaryFields")?.label,
          value: getCurrentFieldValue("primaryFields")?.value,
        },
      ],
      secondaryFields: [
        {
          label: getCurrentFieldValue("secondaryFields")?.label,
          value: getCurrentFieldValue("secondaryFields")?.value,
        },
      ],
      auxiliaryFields: [
        {
          label: getCurrentFieldValue("auxiliaryFields")?.label,
          value: getCurrentFieldValue("auxiliaryFields")?.value,
        },
      ],
    },
  };

  return (
    <>
      <div className="credential credentials">
        <div className="width-900 m-auto bg-white rounded-10 p-20">
          <MainTitle title={constants.componentName.issueCredential} />
          <p className="font-14 color-light-grey mb-20">
            Issue your credentials on the go.
          </p>
          <Row>
            <Col sm="12" lg="6" className="left-data">
              <Scrollbars
                style={{ height: 670 }}
                renderThumbHorizontal={(props) => (
                  <div {...props} className="thumb-horizontal vertical" />
                )}
                renderThumbVertical={(props) => (
                  <div {...props} className="thumb-vertical vertical" />
                )}
                className="credential-scroll"
              >
                <form className="mb-20" style={{ padding: 20 }}>
                  {/* schema list dropdown */}
                  <SelectSchemaDropdown
                    schemaList={schemaList}
                    handleOnSchemaChange={handleOnSchemaChange}
                    errors={errors}
                    touched={touched}
                  />
                  {/* hodler list dropdown */}
                  <SelectHolderDropdown
                    holdersList={holdersList}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                  />
                  {/* dynamic fields */}
                  {selectedSchema &&
                    Object.keys(selectedSchema)?.map((keyName, index) => {
                      // @ts-ignore
                      return selectedSchema[keyName]?.map((field: any) => {
                        return field.dataType === "string" ||
                          field.dataType === "number" ? (
                          <StringInputField
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            values={values}
                            field={field}
                            isSubmitClicked={isSubmitClicked}
                          />
                        ) : (
                          field.dataType === "date" && (
                            <DateInputField
                              formik={formik}
                              setFieldValue={setFieldValue}
                              field={field}
                              key={field.key}
                              touched={touched}
                              values={values}
                            />
                          )
                        );
                      });
                    })}
                  {/* Expiration Date */}
                  <>
                    {expiryDateVisible && (
                      <FormGroup className="mb-20 position-relative">
                        <Label
                          className="font-14 fw-bold color-black2 mb-10"
                          for="exampleDate"
                        >
                          Expiry Date
                        </Label>
                        {/* @ToDo: selecting minimum date from datepicker, fix it. currently hiding current minimym date with css */}
                        <Flatpickr
                          value={values.expiration}
                          className="font-14 fw-normal color-black2 bg-white grey-border custom-input h-50px w-100"
                          // @ts-ignore
                          selected={values.expiration}
                          options={{ minDate: getMinDateTomorrow() }}
                          onChange={(date: any) => {
                            setFieldValue("expiration", date[0]);
                          }}
                        />
                        {/* hide today date which is the minimum date to be selected */}
                        <button
                          type="button"
                          className="bg-transparent border-none position-absolute calendar-btn"
                        >
                          <i className="far fa-calendar-alt color-light-grey font-14"></i>
                        </button>
                      </FormGroup>
                    )}
                    {/* credential status */}
                    <FormGroup
                      className={currentSchemaStatus ? "mb-20" : "d-none"}
                    >
                      <Label className="font-14 fw-bold color-black2 mb-10">
                        Credential Status
                      </Label>
                      <Input
                        type="select"
                        disabled={
                          !values.credentialSchemaId || !currentSchemaStatus
                        }
                        className="font-14 color-black2 form-select custom-select bg-white h-50px border-gray"
                        aria-label="Default select Status"
                        role={"button"}
                        onChange={(event) => {
                          setFieldValue("statusFlag.value", event.target.value);
                        }}
                        invalid={Boolean(
                          errors?.statusFlag?.value &&
                            touched?.statusFlag?.value
                        )}
                      >
                        <option
                          selected
                          disabled
                          className="font-14 fw-normal color-black2"
                          value=""
                        >
                          Select
                        </option>
                        {currentSchemaStatus?.list?.length > 0 &&
                          currentSchemaStatus?.list.map(
                            (status: any, index: number) => {
                              return (
                                <option
                                  className="font-14 fw-normal color-black2"
                                  value={status.value}
                                  key={index}
                                >
                                  {status.label}
                                </option>
                              );
                            }
                          )}
                      </Input>
                      <FormFeedback>{errors?.statusFlag?.value}</FormFeedback>
                    </FormGroup>
                  </>
                  <button
                    className="bg-blue color-white w-100 rounded-2 py-15 font-14"
                    type="button"
                    onClick={() => {
                      setIssubmitClicked(true);
                      handleSubmit();
                    }}
                  >
                    Submit
                  </button>
                </form>
              </Scrollbars>
            </Col>
            <Col sm="12" lg="6">
              <SchemaCardPreview
                credentialSchema={credentialSchema}
                type="issuer"
              />
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default IssueCredential;
