import { apiEndPoint } from "../utils/constants/apiEndPoints";
import HTTPService from "../utils/httpservice";

export const getHoldersListAPI = (params: {
  page: number;
  limit: number;
  search?: string;
}): Promise<IgetHoldersListAPI> => {
  return HTTPService.get(apiEndPoint.getHoldersAPI, { params });
};

export interface IgetHoldersListAPIRes {
  createdAt: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  _id: string;
}

interface IgetHoldersListAPI {
  message: string;
  status: number;
  data: IgetHoldersListAPIRes[];
  count: number;

}