import { SchemaCardPreview } from "components/schemaCard";
import { memo } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import MainTitle from "./MainTitle";

const Review = ({ credentialSchema }: any) => {
  return (
    <>
      <div className="m-auto bg-white rounded-10 p-20 review">
        <MainTitle title="Review Schema" />
        <p className="font-14 color-light-grey mb-20">
          Issue your credentials on the go.
        </p>
        <Row>
          <Col sm="12" lg="6">
            <div className="d-flex align-items-start mb-20">
              <FormGroup className="w-100">
                <Label className="font-14 fw-bold color-black2 mb-10">
                  Name
                </Label>
                <Input
                  type="text"
                  value={credentialSchema?.name}
                  disabled
                  className="font-14 fw-normal color-black2 bg-gray1 grey-border custom-input h-50px"
                />
              </FormGroup>
            </div>
            <FormGroup>
              <Label className="font-14 fw-bold color-black2 mb-10">
                Description
              </Label>
              <Input
                style={{
                  minHeight: "calc(2em + 3.75rem + 2px)",
                  lineHeight: "26px",
                }}
                type="textarea"
                disabled
                value={credentialSchema?.comments}
                className="font-14 fw-normal bg-gray1 color-black2 grey-border custom-input description"
              />
            </FormGroup>
          </Col>
          <Col sm="12" lg="6">
            <SchemaCardPreview credentialSchema={credentialSchema} type="" />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default memo(Review);
