import { IgetDashboardAPIResData } from "actions/dashboardAPI";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { routerLinks } from "utils/constants";
import { constants } from "utils/constants/constants";
import RightArrow from "../../../assets/images/svg/right-arrow.svg";
import CountCard from "./CountCard";

interface ICountInformation {
  data: IgetDashboardAPIResData;
}

const CountInformation: FC<ICountInformation> = ({ data }) => {
  const navigate = useNavigate();

  const redirectTo = (url: string) => {
    navigate(`${url}`);
  };
  const goToTipsSection = () => {
    const element = document.getElementById("tips_section");
    element?.scrollIntoView({ behavior: "smooth" });
  };
  
  return (
    <div className="d-flex flex-wrap justify-content-between list-box rounded-10">
      <CountCard
        title={constants.componentName.holders}
        count={data.holderCount}
        onClick={() => redirectTo(routerLinks.holders)}
      />
      <CountCard
        title={constants.componentName.credentialSchemas}
        count={data.schemaCount}
        onClick={() => redirectTo(routerLinks.credentials)}
      />
      <CountCard
        title={constants.componentName.issueCredentials}
        count={data.issuedCredCount}
        onClick={() => redirectTo(routerLinks.issuecredential)}
      />
      <CountCard
        title={constants.componentName.verifiers}
        count={data.verifierCount}
        onClick={() => redirectTo(routerLinks.verifiers)}
      />
      <div
        className="d-flex align-items-center justify-content-between box text-left"
        onClick={goToTipsSection}
      >
        <div>
          <p className="font-26 color-black1 m-0">How to</p>
          <p className="font-26 fw-900 color-black1 m-0 pr-10">Get Started</p>
        </div>
        <img src={RightArrow} alt="right-arrow" />
      </div>
    </div>
  );
};

export default CountInformation;
