import { SubTitle } from "components/common";
import { Row } from "reactstrap";
import { triggerComingSoon } from "utils/functions";
import TipsCard from "./TipsCard";

const TipsInformation = () => {
  return (
    <div id="tips_section" className="list-box rounded-10 mt-20">
      <div className="mb-20">
        <SubTitle title="Tips" />
      </div>
      <Row>
        <TipsCard
          title="1. Read the getting started"
          paragraph="Get started building an application in the language of your choice."
          btnName="Read more"
          btnClick={triggerComingSoon}
        />
        <TipsCard
          title="2. Try out the tutorials"
          paragraph="Get started building an application in the language of your choice."
          btnName="Read more"
          btnClick={triggerComingSoon}
        />
        <TipsCard
          title="3. Learn about our Identity Wallets"
          paragraph="Get started building an application in the language of your choice."
          btnName="Read more"
          btnClick={triggerComingSoon}
        />
        <TipsCard
          title="4. Use our APIs"
          paragraph="Get started building an application in the language of your choice."
          btnName="Read more"
          btnClick={triggerComingSoon}
        />
      </Row>
    </div>
  );
};

export default TipsInformation;
