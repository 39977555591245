import { memo } from "react";

const CountCard = ({ title, count, ...rest }: ICountCard) => {
  return (
    <div className="d-flex align-items-center box" {...rest}>
      <p className="font-40 fw-900 color-black1 m-0 pr-10">{count}</p>
      <p className="font-20 color-black1 m-0">{title}</p>
    </div>
  );
};

export default memo(CountCard);

interface ICountCard {
  title: string;
  count: number;
  onClick: () => void;
}
