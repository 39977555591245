
import { IgetAvailableAppAPIData, getAvailableAppAPI } from "actions/availableAppAPI";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { setLoading } from "store/loaderSlice";
import { constants } from "utils/constants/constants";
import { environment } from "utils/environment/environment";
import { getDecryptedSessionStorage, goToPage, setEncryptedSessionStorage } from "utils/functions";
import { ReactComponent as Menu } from "../../../../assets/images/svg/menu.svg";
import HeaderAppComponent from "../../HeaderAppComponent";

const Application = () => {
  const dispatch = useDispatch();
  
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [availableApp, setAvailableApp] = useState<IgetAvailableAppAPIData[]>(
    []
  );
  
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  
  const getAvailableAppAction = () => {
    dispatch(setLoading(true));
    getAvailableAppAPI().then((res) => {
      if (res.status === 200) {
        setEncryptedSessionStorage(
          constants.sessionStorageKeys.appList,
          res.data
        );
        setAvailableApp(res.data);
      }
      dispatch(setLoading(false));
    });
  };
  
  useEffect(() => {
    const appData = getDecryptedSessionStorage(
      constants.sessionStorageKeys.appList
    );
    if (appData) {
      setAvailableApp(appData);
    } else {
      getAvailableAppAction();
    }
  }, []);

  return (
    <>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        className="d-flex align-items-center justify-content-center header-dropdown"
      >
        <DropdownToggle title="Application" className="menu-button w-100 h-100">
          <Menu />
        </DropdownToggle>
        <DropdownMenu className="rounded-10 menu-list border-grey">
          {/* application component */}
          <div className="projects-section">
            <DropdownItem className="d-flex flex-wrap project-list">
              {availableApp.length === 0 ? (
                <HeaderAppComponent
                  name={"Account"}
                  emailIcon={true}
                  onClick={environment.accountsPortalDomain}
                />
              ) : (
                availableApp.map((data, index) => {
                  if (data?.isAvailable === 1) {
                    return (
                      <HeaderAppComponent
                        key={index}
                        imgSrc={data?.logoIcon}
                        name={data?.name}
                        emailIcon={data?.name === "Account" ? true : false}
                        onClick={data?.redirect_url}
                      />
                    );
                  }
                })
              )}
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem className="d-flex flex-wrap project-list">
              {availableApp.map((data, index) => {
                if (data?.isAvailable === 0) {
                  return (
                    <HeaderAppComponent
                      key={index}
                      imgSrc={data?.logoIcon}
                      name={data?.name}
                      disable="disabled"
                    />
                  );
                }
              })}
            </DropdownItem>
          </div>
          <button
            className="font-14 fw-bold color-blue more-project"
            onClick={() => goToPage(environment.moreFromEdexaLibrary)}
          >
            More from edeXa Library
          </button>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default Application;
