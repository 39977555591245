import { createSlice } from '@reduxjs/toolkit';
import { constants } from "utils/constants/constants";
import { getDecryptedLocalStorage } from "../utils/functions/commonFunctions";

const initialState = {
  user: getDecryptedLocalStorage(constants.localStorageKeys.userInfo),
};

const userDetailsSlice = createSlice(
  {
    name: "userDetails",
    initialState,
    reducers: {
      onUserDetails: (state, action) => {
        state.user = JSON.parse(action.payload);
      }
    }
  }
);

export const { onUserDetails } = userDetailsSlice.actions
export default userDetailsSlice.reducer